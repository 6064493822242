import React from 'react';
import { getTask } from '../actions/tasks';


// const customStyles = {
//   content: {
//     top: '25%',
//     left: '30%',
//     width: '20vw',
//     right: 'auto',
//     bottom: 'auto',
//     height: 'auto',
//     transform: 'translate(-5%, -5%)',
//   }
// };

class VerifyAcct extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      returnMsg: '',
      showForm: true,
      taskid: this.props.match.params.taskid,
      aoidescr: '',
      stat: 'start',
      updStatus: '',
      updMsg: 'Verifying account...',
    };
  }

  closeModal = () => {
    this.setState({ modalIsOpen: false });
    this.props.history.push('/');
  }

  componentDidMount() {
    getTask(this.state.taskid)
      .then(res => {
        this.setState({ updStatus: res.status, updMsg: res.message })
      });
  }

  render() {
    return (
      <div id="unsubscribe">
        <h3>{this.state.updMsg}
        </h3>
        {this.state.updStatus === "ok" &&
          <form onSubmit={this.onSubmit} className={this.state.showForm !== true ? 'hidden' : ''}>
            <div>
              <div className="returnMsg">{this.state.returnMsg}</div>
              <br />
              <button type="reset" className="drawicon" onClick={this.closeModal}>
                Close
              </button>
            </div>
          </form>
        }
        </div>
        )
  }
}


export default VerifyAcct;
