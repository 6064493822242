// SET_LNG
export const setLng = ( lng ) => ({
  type: 'SET_LNG',
  lng: lng
});

// SET_LAT
export const setLat = ( lat ) => ({
  type: 'SET_LAT',
  lat: lat
});
  
// SET_ZOOM
export const setZoom = ( zoom ) => ({
  type: 'SET_ZOOM',
  zoom: zoom
});

// SET_ALERTS_DATE_RANGE
export const setAlertsDateRange = ( alertsDateRange ) => ({
  type: 'SET_ALERTS_DATE_RANGE',
  alertsDateRange: alertsDateRange
});

// SHOW_AOIS
export const setShowAois = ( showAois ) => ({
  type: 'SHOW_AOIS',
  showAois: showAois
});

// SET_AOI
export const setAoi = ( aoi ) => ({
  type: 'SET_AOI',
  aoi: aoi
});

// SET_AOIS
export const setAois = ( aois ) => ({
  type: 'SET_AOIS',
  aois: aois
});

// SET_AOI_SELECTED
export const setAoiSelected = ( aoiSelected ) => ({
  type: 'SET_AOI_SELECTED',
  aoiSelected: aoiSelected
});

// SET_PLANET_DAILY_IMAGE
export const setPlanetDailyImage = ( planetDailyImage ) => ({
  type: 'SET_PLANET_DAILY_IMAGE',
  planetDailyImage: planetDailyImage,
  // planetDailyImageClicked: planetDailyImageClicked
});

// SET_MAP
export const setMap = ( map ) => ({
  type: 'SET_MAP',
  map: map
});

/* drawingType is used to differentiate drawing an AOI ("newaoi") or DW ("DW") or annotation (null) */
export const setDrawingType = ( drawingType ) => ({
  type: 'SET_DRAWING_TYPE',
  drawingType: drawingType
});


// SET_SHOW_ANNOTATIONS
export const setShowAnnotations = ( showAnnotations ) => ({
  type: 'SET_SHOW_ANNOTATIONS',
  showAnnotations: showAnnotations
});

export const setNewAoiShape = ( newaoiShape ) => ({
  type: "SET_NEWAOI_SHAPE",
  newaoiShape: newaoiShape
})

export const setAnnotationid = (annotationid) => ({
  type: "SET_ANNOTATIONID",
  annotationid: annotationid
})

export const setDisplayPanel = (displayPanel) => ({
  type: "SET_DISPLAY_PANEL",
  displayPanel: displayPanel
})

export const setAlertsSidebar = (alertsSidebar) => ({
  type: "SET_ALERTS_SIDEBAR",
  alertsSidebar: alertsSidebar
})

export const setAoiPolygon = (aoiPolygon) => ({
  type: "SET_AOI_POLYGON",
  aoiPolygon: aoiPolygon
})

export const setShowLogin = (showLogin) => ({
  type: "SET_SHOW_LOGIN",
  showLogin: showLogin
})

export const setSaveThisAoi = ( saveThisAoi ) => ({
  type: "SET_SAVE_THIS_AOI",
  saveThisAoi: saveThisAoi
})

export const setLimitToAoi = ( limitToAoi ) => ({
  type: "LIMIT_TO_AOI",
  limitToAoi: limitToAoi
})

export const setRegion = ( region ) => ({
  type: "SET_REGION",
  region: region
})

export const setReloadAois = ( reloadAois ) => ({
  type: "SET_RELOAD_AOIS",
  reloadAois: reloadAois
})

// export const setInfoTopic = ( infoTopic) => ({
//   type: "SET_INFO_TOPIC",
//   infoTopic: infoTopic
// })

export const setEditAoi = ( editAoi ) => ({
  type: "SET_EDIT_AOI",
  editAoi: editAoi
})

export const setReportId = ( reportId ) => ({
  type: "SET_REPORT_ID",
  reportId: reportId
})

export const setReportDetail = ( reportDetail ) => ({
  type: "SET_REPORT_DETAIL",
  reportDetail: reportDetail
})

export const setShowPwChange = ( showPwChange ) => ({
  type: "SET_SHOW_PW_CHANGE",
  showPwChange: showPwChange
})

export const setCloudCoverage = ( cloudCoverage ) => ({
  type: "SET_CLOUD_COVERAGE",
  cloudCoverage: cloudCoverage
})

export const setPlanetDateRange = ( planetDateRange ) => ({
  type: 'SET_PLANET_DATE_RANGE',
  planetDateRange: planetDateRange
});

export const setShowRegister = ( showRegister ) => ({
  type: 'SET_SHOW_REGISTER',
  showRegister: showRegister
})

export const setShowAddAoi = ( showAddAoi ) => ({
  type: 'SET_SHOW_ADD_AOI',
  showAddAoi: showAddAoi
})

export const setCluster = (cluster) => ({
  type: 'SET_CLUSTER',
  cluster: cluster
})

export const setShowAlertsInfoBox = (showAlertsInfoBox) => ({
  type: 'SET_SHOW_ALERTS_INFO_BOX',
  showAlertsInfoBox: showAlertsInfoBox
})

export const setShowLabels = (showLabels) => ({
  type: 'SET_SHOW_LABELS',
  showLabels: showLabels
})

export const setDrawShape = (drawShape) => ({
  type: 'SET_DRAW_SHAPE',
  drawShape: drawShape
})

export const setUserProfile = (userProfile) => ({
  type: 'SET_USER_PROFILE',
  userProfile: userProfile
})

export const setHeaderBanner = (headerBanner) => ({
  type: 'SET_HEADER_BANNER',
  headerBanner: headerBanner
})

export const setShowBanner = (showBanner) => ({
  type: 'SET_SHOW_BANNER',
  showBanner: showBanner
})

export const setLayers = (layers) => ({
  type: 'SET_LAYERS',
  layers: layers
})

export const setSatImageType = (satImageType) => ({
  type: 'SET_SAT_IMAGE_TYPE',
  satImageType: satImageType
})

export const setSatImage = (satImage) => ({
  type: 'SET_SAT_IMAGE',
  satImage: satImage
})

export const setLayer = (layer) => ({
  type: 'SET_LAYER',
  layer: layer
})

export const setExp = (exp) => ({
  type: 'SET_EXP',
  exp: exp
})

export const setFeeds = (feeds) => ({
  type: 'SET_FEEDS',
  feeds: feeds
})

export const setSelectedFeeds = (selectedFeeds) => ({
  type: 'SET_SELECTED_FEEDS',
  selectedFeeds: selectedFeeds
})

export const setIssueFeeds = (issueFeeds) => ({
  type: 'SET_ISSUE_FEEDS',
  issueFeeds: issueFeeds
})

export const setAnnotations = (annotations) => ({
  type: 'SET_ANNOTATIONS',
  annotations: annotations
})

export const setShowSave = (showSave) => ({
  type: 'SET_SHOW_SAVE',
  showSave: showSave
})

export const setShowOpen = (showOpen) => ({
  type: 'SET_SHOW_OPEN',
  showOpen: showOpen
})

export const setMapType = (mapType) => ({
  type: 'SET_MAP_TYPE',
  mapType: mapType
})

// export const setPublicUser = (publicUser) => ({
//   type: 'SET_PUBLIC_USER',
//   publicUser: publicUser
// })

// export const setMapviewName = (mapviewName) => ({
//   type: 'SET_MAPVIEW_NAME',
//   mapviewName: mapviewName
// })

export const setAlertsKeyword = (alertsKeyword) => ({
  type: 'SET_ALERTS_KEYWORD',
  alertsKeyword: alertsKeyword
})

export const setAlertsSearchReady = (alertsSearchReady) => ({
  type: 'SET_ALERTS_SEARCH_READY',
  alertsSearchReady: alertsSearchReady
})

export const setIncludeLegend = (includeLegend) => ({
  type: 'SET_INCLUDE_LEGEND',
  includeLegend: includeLegend
})

export const setShowTimeline = (showTimeline) => ({
  type:'SET_SHOW_TIMELINE',
  showTimeline: showTimeline
})

export const setShowLabs = (showLabs) => ({
  type:'SET_SHOW_LABS',
  showLabs: showLabs
})

// SET_COMPARE_IMAGES
export const setCompareImages = ( compareImages ) => ({
  type: 'SET_COMPARE_IMAGES',
  compareImages: compareImages
});

export const setIssue = (issue) => ({
  type:'SET_ISSUE',
  issue: issue
})

export const setLockMap = (lockMap) => ({
  type:'SET_LOCK_MAP',
  lockMap: lockMap
})

export const setSetbacks = (setbacks) => ({
  type:'SET_SETBACKS',
  setbacks: setbacks
})

export const setLayerTimeline = (layerTimeline) => ({
  type:'SET_LAYER_TIMELINE',
  layerTimeline: layerTimeline
})

export const setLayerTimelineMap = (layerTimelineMap) => ({
  type:'SET_LAYER_TIMELINE_MAP',
  layerTimelineMap: layerTimelineMap
})

export const setMessage = (message) => ({
  type:'SET_MESSAGE',
  message: message
})

export const setShowSatellite = (showSatellite) => ({
  type:'SET_SHOW_SATELLITE',
  showSatellite: showSatellite
})

export const setShowPublicIssues = (showPublicIssues) => ({
  type:'SET_SHOW_PUBLIC_ISSUES',
  showPublicIssues: showPublicIssues
})

export const setUga = (uga) => ({
  type:'SET_UGA',
  uga: uga
})

export const setReturnPosition = (returnPosition) => ({
  type:'SET_RETURN_POSITION',
  returnPosition: returnPosition
})

export const setReportFrom = (reportFrom) => ({
  type:'SET_REPORT_FROM',
  reportFrom: reportFrom
})

export const setHelpKey = (helpKey) => ({
  type:'SET_HELP_KEY',
  helpKey: helpKey
})

export const setMapLayersReadyToShow = (mapLayersReadyToShow) => ({
  type:'SET_MAP_LAYERS_READY_TO_SHOW',
  mapLayersReadyToShow: mapLayersReadyToShow
})

export const setLayerChanges = (layerChanges) => ({
  type:'SET_LAYER_CHANGES',
  layerChanges: layerChanges
})

export const setImageOpacity = (imageOpacity) => ({
  type:'SET_IMAGE_OPACITY',
  imageOpacity: imageOpacity
})

export const setShowElevation = (showElevation) => ({
  type:'SET_SHOW_ELEVATION',
  showElevation: showElevation
})

export const setOrgAlerts = (orgAlerts) => ({
  type:'SET_ORG_ALERTS',
  orgAlerts: orgAlerts
})

export const setAgendaJson = (agendaJson) => ({
  type:'SET_AGENDA_JSON',
  agendaJson: agendaJson
})

export const setCombinedImgs = (combinedImgs) => ({
  type:'SET_COMBINED_IMGS',
  combinedImgs: combinedImgs
})

export const setShowCompare = (showCompare) => ({
  type:'SET_SHOW_COMPARE',
  showCompare: showCompare
})

export const setSaveAnnotations = (saveAnnotations) => ({
  type:'SET_SAVE_ANNOTATIONS',
  saveAnnotations: saveAnnotations
})

export const setMapPosition = (mapPosition) => ({
  type:'SET_MAP_POSITION',
  mapPosition: mapPosition
})

export const setBbox = (bBox) => ({
  type: 'SET_BBOX',
  bBox: bBox
})

export const setLayersY = (layersY) => ({
  type: 'SET_LAYERSY',
  layersY: layersY
})

export const setGamma = (gamma) => ({
  type: 'SET_GAMMA',
  gamma: gamma
})

export const setMapInset = (mapInset) => ({
  type: 'SET_MAP_INSET',
  mapInset: mapInset
})

export const setGeometry = (geometry) => ({
  type: 'SET_GEOMETRY',
  geometry: geometry
})

export const setBrightness = (brightness) => ({
  type: 'SET_BRIGHTNESS',
  brightness: brightness
})

// export const setCompareMap1 = (compareMap1) => ({
//   type: 'SET_COMPARE_MAP_1',
//   compareMap1: compareMap1
// })

// export const setCompareMap2 = (compareMap2) => ({
//   type: 'SET_COMPARE_MAP_2',
//   compareMap2: compareMap2
// })

export const setShowBbox = (showBbox) => ({
  type: 'SET_SHOW_BBOX',
  showBbox: showBbox
})

export const setShowSettings = (showSettings) => ({
  type: 'SET_SHOW_SETTINGS',
  showSettings: showSettings
})

export const setScreenshot = (screenshot) => ({
  type: 'SET_SCREENSHOT',
  screenshot: screenshot
})

export const setCompareUpdateSide = (compareUpdateSide) => ({
  type: 'SET_COMPARE_UPDATE_SIDE',
  compareUpdateSide: compareUpdateSide
})

export const setBasemap = (basemap) => ({
  type: 'SET_BASEMAP',
  basemap: basemap
})

export const setTheme = (theme) => ({
  type: 'SET_THEME',
  theme: theme
})

export const setShowNotLoggedInOptions = (showNotLoggedInOptions) => ({
  type: 'SET_SHOW_NOT_LOGGED_IN_OPTIONS',
  showNotLoggedInOptions: showNotLoggedInOptions
})

export const setShowInitTutorial = (showInitTutorial) => ({
  type: 'SET_SHOW_INIT_TUTORIAL',
  showInitTutorial: showInitTutorial
})

export const setSaveMap = (saveMap) => ({
  type: 'SET_SAVE_MAP',
  saveMap: saveMap
})

export const setIssueLayers = (issueLayers) => ({
  type: 'SET_ISSUE_LAYERS',
  issueLayers: issueLayers
})

export const setMapTitle = (mapTitle) => ({
  type: 'SET_MAP_TITLE',
  mapTitle: mapTitle
})
