import React from 'react';
import { getAoi } from '../actions/aois';
import { inactivateAoi } from '../actions/aois';

const customStyles = {
  content: {
    top: '25%',
    left: '30%',
    width: '20vw',
    right: 'auto',
    bottom: 'auto',
    height: 'auto',
    transform: 'translate(-5%, -5%)',
  }
};

class Unsubscribe extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      returnMsg: '',
      showForm: true,
      id: this.props.match.params.id,
      aoidescr: '',
      stat: 'start',
    };
  }

  closeModal = () => {
    this.setState({modalIsOpen: false});
    this.props.history.push('/');
  }

  onSubmit = e => {
    e.preventDefault();
    inactivateAoi(null, this.state.id, null)
      .then(res => {
        if (res == 200) {
          this.setState({ returnMsg: 'Successfully unsubscribed', stat: 'success' });
        }
      });
  }

  componentDidMount() {
    let res = getAoi(this.state.id)
      .then(res => {
        this.setState({aoidescr: res.data.aoidescr});
      })
  }

  render() {
    return (
        <div id="unsubscribe">
          <h3>Unsubscribe from new alerts for area of interest = {this.state.aoidescr}
            ?
          </h3>
          <form onSubmit={this.onSubmit} className={this.state.showForm !== true ? 'hidden' : ''}>
            <div>
              <div className="returnMsg">{this.state.returnMsg}</div>
              <br/>
              { this.state.stat === 'start' &&
                <div>
                <button type="submit" className="drawicon">Unsubscribe</button>
                &nbsp;
                <button type="reset" className="drawicon" onClick={this.closeModal}>
                  {this.state.stat === 'start' ? 'Cancel' : 'Close' }
                </button>
                </div>
              }
            </div>
          </form>
        </div>
    )
  }
}


export default Unsubscribe;