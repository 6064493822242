import { postLog } from '../utils/log';
import { APIBaseUrl } from '../utils/constants';
import { logAOIEngaged } from '../utils/analytics';
import { getBoundingBox } from '../utils/geometry';
import { dispatch } from 'd3';


export const loadAois = async (token, email, setAois, setSaveThisAoi, setReloadAois, setShowLogin, logout, issue = null) => {
    let headers = {
        "Content-Type": "application/json",
    };
    if (token) {
        headers["Authorization"] = `Token ${token}`;
    }
    if (issue !== undefined && issue !== null && issue.issue_type == "showaois") {
        issue = issue.pk;
        email = '';
    }
    issue = "";
    try {
        return fetch(`${APIBaseUrl}/rssemailsubscription/?email=${email}&issue=${issue}`, { headers, })
            .then(res => {
                if (res.status < 500) {
                    return res.json().then(data => {
                        return { status: res.status, data };
                    })
                } else {
                    console.log("Server Error!");
                    throw res.status;
                }
            })
            .then(res => {
                if (res.status === 200) {
                    setAois(res.data);
                    setReloadAois(false);
                    setSaveThisAoi(false);
                    return res.data.results;
                } else if (res.status >= 400 && res.status < 500) {
                    if (res.status === 401) {
                        logout();
                    }
                    else
                        throw res.status;
                }
            })
    }
    catch {
        logout();
    }
}


export const inactivateAoi = async (token = null, id, setReloadAois = null, aoi = null) => {
    let headers = {
        "Content-Type": "application/json",
    };
    if (token) {
        headers["Authorization"] = `Token ${token}`;
    }

    if (!aoi?.geom) {
        const loadedAoi = await getAoiFullDetails(id);
        aoi = loadedAoi?.length ? loadedAoi[0] : loadedAoi;
    }

    return fetch(`${APIBaseUrl}/inactivate_aoi/?id=${id}`, { headers, })
        .then(res => {
            if (res.status < 500) {
                return res.json().then(data => {
                    if (aoi){
                        const type = aoi.region ? "predefined" : "custom";
                        const boundingBox = getBoundingBox(aoi.simple_geom ?? aoi.geom);
                        logAOIEngaged(
                            "delete",
                            type,
                            boundingBox
                        )
                    }
                    return { status: res.status, data };
                })
            } else {
                console.log("Server Error!");
                throw res;
            }
        })
        .then(res => {
            if (res.status === 200) {
                if (setReloadAois) {
                    setReloadAois(true);
                    return res.data.results;
                }
                else
                    return res.status;
            } else if (res.status >= 400 && res.status < 500) {
                console.log("Server Error!", res.status);
            }
        })
}

export const updateAoi = (token, id, passedGeom, area, email, setAois, setSaveThisAoi, setReloadAois) => {
    let wkt = passedGeom;
    let geom = wkt.substr(wkt.indexOf('POLYGON'), wkt.length)
    let headers = {
        "Content-Type": "application/json",
    };
    if (token) {
        headers["Authorization"] = `Token ${token}`;
    }
    let body = JSON.stringify({ id, geom, area });
    return fetch(`${APIBaseUrl}/update_aoi/`, { headers, body, method: "POST" })
        .then(res => {
            if (res.status < 500) {
                return res.json().then(data => {
                    return { status: res.status, data };
                })
            } else {
                console.log("Server Error!");
                throw res;
            }
        })
        .then(res => {
            if (res.status === 200) {
                loadAois(token, email, setAois, setSaveThisAoi, setReloadAois);
            } else if (res.status === 403 || res.status === 401) {
                dispatch({ type: "AUTHENTICATION_ERROR", data: res.data });
                throw res.data;
            } else {
                console.log("SaveAoi FAILED");
                throw res.data;
            }
        })
}

export const saveAoi = (aoi, aoidescr, email, region, token, setAois, setSaveThisAoi, setReloadAois, type ) => (dispatch) => {

        let wkt = aoi.geom;
        let geom = null;
        if (wkt.includes('MULTIPOLYGON'))
            geom = wkt.substr(wkt.indexOf('MULTIPOLYGON'), wkt.length)
        else if (wkt.includes('POLYGON'))
            geom = wkt.substr(wkt.indexOf('POLYGON'), wkt.length)
        else if (wkt.includes('MULTILINESTRING'))
            geom = wkt.substr(wkt.indexOf('MULTILINESTRING'), wkt.length)
        else if (wkt.includes('LINESTRING'))
            geom = wkt.substr(wkt.indexOf('LINESTRING'), wkt.length);

        const boundingBox = getBoundingBox(aoi.geom);

        let area = Math.round(aoi.area * 10) / 10;
        let headers = {
            "Content-Type": "application/json",
            'Accept': 'application/json'
        };
        if (token) {
            headers["Authorization"] = `Token ${token}`;
        }

        let body = JSON.stringify({ aoidescr, geom, email, area, region });
        return fetch(`${APIBaseUrl}/insert_aoi/`, { headers, body, method: "POST" })
            .then(res => {
                if (res.status < 500) {
                    return res.json().then(data => {
                        logAOIEngaged("create", type, boundingBox)
                        return { status: res.status, data };
                    })
                } else {
                    console.log("Server Error!");
                    throw res;
                }
            })
            .then(res => {
                if (res.status === 200) {
                    loadAois(token, email, setAois, setSaveThisAoi, setReloadAois);
                } else if (res.status === 403 || res.status === 401) {
                    dispatch({ type: "AUTHENTICATION_ERROR", data: res.data });
                } else {
                    console.log("SaveAoi FAILED");
                }
            })

    }

const getAoiFullDetails = async (id, token) => {
    const headers = {
        "Content-Type": "application/json",
    };
    if (token) {
        headers["Authorization"] = `Token ${token}`;
    }
    try {
        const response = await fetch(`${APIBaseUrl}/rssemailsubscription/?id=${id}`, headers) 
        if (response.status >= 400){
            throw new Error("Failed to fetch single aoi")
        }
        return await response.json();
    } catch (error){
        console.error(error);
        return null;
    }
}


export const clickSubscriptionId = (subId, token, setAoi, setAoiSelected, setShowAddAoi, setLimitToAoi, logout, limitToAoi, userId = -1) => (dispatch) => {
    let headers = {
        "Content-Type": "application/json",
    };
    if (token) {
        headers["Authorization"] = `Token ${token}`;
    }

   return fetch(`${APIBaseUrl}/rssemailsubscription/?id=${subId}`, { headers, })
        .then(async res => {
            if (res.status < 500) {
                const data = await res.json();
                return { status: res.status, data };
            } else {
                console.log("Server Error!");
                throw res;
            }
        })
        .then(res => {
            if (res.status === 200) {
                if (res.data.length > 0) {
                    if (limitToAoi != null) {
                        setLimitToAoi(limitToAoi);
                    }
                    const subscription = res.data[0];
                    setAoi(subscription);
                    setAoiSelected(true);
                    if (setShowAddAoi) setShowAddAoi(false);    
                    postLog(userId, 'clickSubscriptionId', subscription.aoidescr, subscription.id);
                    return subscription;
                }
            } else if (res.status >= 400 && res.status < 500) {
                if (res.status === 401)
                    logout();
                else {
                    dispatch({ type: "AUTHENTICATION_ERROR", data: res.data });
                }
            }
        })
}

export const getAoi = (id) => {
    if (!id) return null;
    let headers = {
        "Content-Type": "application/json",
    };
    
    return fetch(`${APIBaseUrl}/${id}/getaoi/`, { headers, })
        .then(res => {
            if (res.status < 500) {
                return res.json().then(data => {
                    return { status: res.status, data };
                })
            } else {
                console.log("Server Error!");
                throw res;
            }
        })
        .then(res => {
            if (res.status === 200) {
                return res
            } else if (res.status >= 400 && res.status < 500) {
                dispatch({ type: "AUTHENTICATION_ERROR", data: res.data });
                throw res.data;
            }
        })
}
