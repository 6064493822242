import React from 'react';
import { getPublicIssues } from '../actions/issue';
import moment from 'moment';
// import * as errorMsgs from '../utils/errorMsgs';
import ReactTable from 'react-table';
import 'react-table/react-table.css'
import 'react-tabs/style/react-tabs.scss';
import 'react-hint/css/index.css';
// import PublicIssueListItem from '../components/PublicIssueListItem';
import Login from '../components/Login';
import Register from '../components/Register';


// let issueContent = '';
const openColumns = [
  {
    Header: <span>Title&nbsp;<i className="fas fa-sort"></i></span>,
    accessor: 'fields.descr',
    style: {
      cursor: "pointer",
      fontSize: 14,
      padding: "1",
      textAlign: "left",
      userSelect: "none",
    },
    Filter: ({ filter, onChange }) => (
      <input type='text'
        placeholder="search"
        value={filter ? filter.value : ''}
        onChange={event => onChange(event.target.value)}
      />
    )
  },
  {
    Header: <span>ID (part of URL)&nbsp;<i className="fas fa-sort"></i></span>,
    accessor: 'pk',
    style: {
      cursor: "pointer",
      fontSize: 14,
      padding: "1",
      textAlign: "left",
      userSelect: "none",
    },
    Filter: ({ filter, onChange }) => (
      <input type='text'
        placeholder="search"
        value={filter ? filter.value : ''}
        onChange={event => onChange(event.target.value)}
      />
    )
  },
  {
    Header: <span>Last Updated&nbsp;<i className="fas fa-sort"></i></span>,
    id: 'fields.dateupdated',
    accessor: c => { return moment(c.fields.dateupdated).format('MM/DD/YY HH:mm:ss a') },
    style: {
      cursor: "pointer",
      fontSize: 14,
      padding: "1",
      textAlign: "left",
      userSelect: "none",
    },
    Filter: ({ filter, onChange }) => (
      <input type='text'
        placeholder="search"
        value={filter ? filter.value : ''}
        onChange={event => onChange(event.target.value)}
      />
    )
  },
]


class PublicIssues extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: true,
      fileName: '', //this.props.issueMap,
      descr: '',
      issueContent: '',
      publicIssues: [],
      errorMsg: '',
      mapSaved: false,
      askWriteOver: false,
      name: '',
      id: '',
      copyMsg: '',
      tabIndex: 0,
      showMyMaps: false,
      msg: null,
      locationStatus: null,
      saved: false,
      searchKeyword: '',
      displayType: 'list',
      status: 'active',
      // msg: 'loading...',
    }
  }

  componentDidMount() {
    this._asyncRequest = this.getPublicIssues();
  }

  getPublicIssues = () => {
    getPublicIssues(this.state.status)
      .then(res => {
        if (res.status === 200) {
          this.setState({ publicIssues: res.data })
        }
        else {
          this.setState({ errorMsg: res.status })
        }
      })
  }

  componentWillUnmount() {
    if (this._asyncRequest) {
      this._asyncRequest.cancel()
    }
  }

  closeModal = () => {
    window.open('/', '_self');
  }

  copyStringToClipboard = () => {
    // Create new element
    var el = document.createElement('textarea');
    // Set value (string to be copied)
    el.value = 'monitor.skytruth.org/issue/' + this.state.fileName;
    // Set non-editable to avoid focus and move outside of view
    el.setAttribute('readonly', '');
    el.style = { position: 'absolute', left: '-62.4375rem' };
    document.body.appendChild(el);
    // Select text inside element
    el.select();
    // Copy text to clipboard
    document.execCommand('copy');
    // Remove temporary element
    document.body.removeChild(el);
    this.setState({ copyMsg: " Copied to clipboard" })
  }

  onRowClick = (state, rowInfo, column, instance) => {
    return {
      onClick: e => {
        let row = this.state.publicIssues[rowInfo.index];
        window.open('/issue/' + row.pk, '_self');
      }
    }
  }

  filterCaseInsensitive = (filter, row) => {
    const id = filter.pivotId || filter.id;
    return (
      row[id] !== undefined ?
        String(row[id].toString().toLowerCase())
          .includes(filter.value.toString().toLowerCase())
        :
        true
    );
  }

  createStaticLink = (aoi_geom, region_geom, map_type, zoom, lat, lng) => {
    let myGeom = aoi_geom;
    if (region_geom != null) {
      myGeom = region_geom;
    }

    function reverseLatLng(geom) {
      let newGeom = '';
      if (geom.indexOf('MULTIPOLYGON') > -1) {
        newGeom = geom.substr(geom.indexOf('(((') + 3, geom.length);
        newGeom = newGeom.replace(')))', '');
      }
      else {
        newGeom = geom.substr(geom.indexOf('((') + 2, geom.length);
        newGeom = newGeom.replace('))', '');
      }
      newGeom = newGeom.replace(/, /g, ',');
      let geomArray = newGeom.split(',');
      newGeom = '';
      for (let i = 0; i < geomArray.length; i++) {
        if (i > 0) newGeom += ',';
        let newPoint = geomArray[i].split(' ');
        newGeom += newPoint[1] + ' ' + newPoint[0];
      }
      return 'SRID=4326;POLYGON ((' + newGeom + '))';
    }

    let geom = null;
    if (myGeom != null) {
      geom = reverseLatLng(myGeom);
      geom = geom.substr(geom.indexOf('((') + 2)
      geom = geom.substr(0, geom.length - 2)
      geom = geom.replace(/,/g, '|');
      geom = geom.replace(/ /g, ',');
    }
    let url;
    if (geom == null)
      url = "https://maps.googleapis.com/maps/api/staticmap?key=AIzaSyDqivEDuAtf9GMe97BQZHXYRCMw0oLHzWI" +
        "&size=200x200&sensor=false&maptype=" + map_type + "&zoom=" + zoom + "&center=" + lat + "," + lng
    else
      url = "https://maps.googleapis.com/maps/api/staticmap?key=AIzaSyDqivEDuAtf9GMe97BQZHXYRCMw0oLHzWI" +
        "&size=200x200&sensor=false&maptype=" + map_type + "&path=color:0xf33f00ff|weight:5|fillcolor:0xFF000033" + "|" +
        geom;
    return url
  }

  clickIssueId = (issueId) => {
    window.open('/issue/' + issueId, '_self');
  }

  handleKeywordChange = (e) => {
    let searchKeyword = e.target.value;
    this.setState({ searchKeyword })
  }

  // listView = () => {
  //   this.setState({ displayType: 'list' })
  // }

  // galleryView = () => {
  //   this.setState({ displayType: 'gallery' })
  // }

  statusChanged = () => {
    let status = this.state.status;
    if (status === "active") status = "archived"
    else status = "active";
    this.setState({ status }, function () {
      this.getPublicIssues();
    })
  }
  render() {
    return (

      <div id="issuesDiv" style={{ "zIndex": "9" }}>

        <div id="issueContent">

          <div id="saveHeader">Public Maps
            <a onClick={this.closeModal} ><i className="far fa-times-circle closeBigModal"></i></a>
          </div>
          <div style={{ "fontSize": "large", "marginTop": "0.5rem", "marginBottom": "1.5rem" }}>
            <a onClick={this.statusChanged} style={{ "textDecoration": this.state.status === "active" ? "underline" : "none" }}>Active</a>&nbsp;| &nbsp;
            <a onClick={this.statusChanged} style={{ "textDecoration": this.state.status === "archived" ? "underline" : "none" }}>Archived</a>
          </div>
          <div>
            Click a public map to view it.&nbsp;Maps that haven't been used in the past year are archived each month.
          </div>
          <br />
          <div id="openIssues">
              <ReactTable
                className="-highlight"
                data={this.state.publicIssues}
                columns={openColumns}
                loading={false}
                showPaginationTop={false}
                showPaginationBottom={false}
                filterable={true}
                defaultFilterMethod={this.filterCaseInsensitive}
                getTdProps={this.onRowClick}
                showPageSizeOptions={false}
                style={{ "height": "95%" }}
                pageSize={this.state.publicIssues.length}
              />
          </div>
          {this.state.msg !== null &&
            <div className="centerDiv" id="writeOver">
              {this.state.msg}
              <button
                className="mapOptionButton topMargin"
                onClick={this.setState({ msg: null })}
                type="button"
              >OK
              </button>
            </div>
          }
          {!this.props.isAuthenticated && this.props.showLogin &&
            <Login
              disabled={!this.props.showLogin}
              setShowLogin={this.props.setShowLogin}
              showLogin={this.props.showLogin}
              isAuthenticated={this.props.isAuthenticated}
              showRegister={this.props.showRegister}
              setShowRegister={this.props.setShowRegister}
            // setPublicUser={this.props.setPublisUser}
            ></Login>
          }
          {this.props.showRegister === true &&
            <Register
              showRegister={this.props.showRegister}
              setShowRegister={this.props.setShowRegister}
              setShowLogin={this.props.setShowLogin}
            ></Register>
          }
        </div>
      </div>
    );
  }
}


export default PublicIssues;
