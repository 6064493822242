import React from 'react';
import { getIssuesByUser, inactivateIssue } from '../actions/issue';
import moment from 'moment';
import ReactTable from 'react-table';
import 'react-table/react-table.css';
import 'react-tabs/style/react-tabs.scss';
import 'react-hint/css/index.css';


let issueContent = '';
const openColumns = [
  {
    Header: "",
    //accessor: 'remove',
    id: 'remove',
    accessor: c => { return <i className="fas fa-trash"></i> },
    width: 30,
    style: {
      cursor: "pointer",
      fontSize: 14,
      padding: "1",
      textAlign: "left",
      userSelect: "none",
    },
    Filter: ({ filter, onChange }) => (
      <div></div>
    )
  },
  {
    Header: <span>Last Updated&nbsp;<i className="fas fa-sort" data-rh="Sort"></i></span>,
    id: 'fields.dateupdated',
    accessor: c => { return moment(c.fields.dateupdated).format('MM/DD/YY HH:mm:ss a') },
    width: 180,
    style: {
      cursor: "pointer",
      fontSize: 14,
      padding: "1",
      textAlign: "left",
      userSelect: "none",
    },
    Filter: ({ filter, onChange }) => (
      <input type='text'
        placeholder="search"
        value={filter ? filter.value : ''}
        onChange={event => onChange(event.target.value)}
      />
    ),
  },
  {
    Header: <span>Title&nbsp;<i className="fas fa-sort" data-rh="Sort"></i></span>,
    accessor: 'fields.descr',
    width: 250,
    style: {
      cursor: "pointer",
      fontSize: 14,
      padding: "1",
      textAlign: "left",
      userSelect: "none",
    },
    Filter: ({ filter, onChange }) => (
      <input type='text'
        placeholder="search"
        value={filter ? filter.value : ''}
        onChange={event => onChange(event.target.value)}
      />
    ),
  },
]


class IssueMapsSave extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: true,
      fileName: '', //this.props.issueMap,
      descr: '',
      lockMap: false,
      issueContent: '',
      mapSavedMsg: '',
      issues: [],
      errorMsg: '',
      mapSaved: false,
      askWriteOver: false,
      name: '',
      id: '',
      copyMsg: '',
      tabIndex: 0,
      showMyMaps: false,
      msg: null,
      locationStatus: null,
      saved: false,
      defaultOpenContent: false,
      fileNameChanged: false,
      fileNamePrefix: '',
      securityOption: { value: 'only me', label: 'only me' },
      emailAccess: '',
      preMsg: '',
      subscribable: false,
      whoAccess: 'anyone',
      fullUrl: null,
    }
  }

  componentDidMount() {
    this._asyncRequest = this.getIssues();
  }

  getIssues = () => {
    getIssuesByUser(this.props.user.id).then(res => {
      if (res.status === 200) {
        if (this.props.issue !== null)
          res.data.map((row) => {
            if (this.props.showSave && row.pk == this.props.issue.pk) {
              this.setFileState(row);
            }
          })
        this.setState({ issues: res.data })
      }
      else {
        this.setState({ errorMsg: res.status })
      }
    })
  }

  componentWillUnmount() {
    if (this._asyncRequest) {
      this._asyncRequest.cancel()
    }
  }

  closeModal = (e) => {
    e.preventDefault();
    e.stopPropagation()
    this.setState({ fileName: '', mapSaved: false, askWriteOver: false, name: '' })
    if (this.props.showSave) this.props.setShowSave(false);
    if (this.props.showOpen) this.props.setShowOpen(false);
  }


  onRowClick = (state, rowInfo, column, instance) => {
    return {
      onClick: e => {
        let row = this.state.issues[rowInfo.index]
        this.setFileState(row);
      }
    }
  }

  filterCaseInsensitive = (filter, row) => {
    const id = filter.pivotId || filter.id;
    return (
      row[id] !== undefined ?
        String(row[id].toString().toLowerCase())
          .includes(filter.value.toString().toLowerCase())
        :
        true
    );
  }

  setFileState = (row) => {
    let whoAccess = row.fields.security_option;
    if (row.fields.discoverable) whoAccess = "anyonePublic";
    this.setState({
      fileName: this.state.fileNamePrefix == '' ? row.pk : row.pk.substring(this.state.fileNamePrefix.length),
      descr: row.fields.descr,
      whoAccess,
      subscribable: row.fields.subscribable,
      lockMap: row.fields.lock_map,
      defaultOpenContent: row.fields.default_open_content,
      mapSaved: false,
      showMyMaps: false,
      emailAccess: row.fields.email_access,
      preMsg: 'The data below was previously saved',
    })
    issueContent = row.fields.content;
  }

  checkIfRemove = (state, rowInfo, column, instance) => {
    return {
      onClick: e => {
        if (column.id == "remove") {
          let row = this.state.issues[rowInfo.index];
          inactivateIssue(row.pk)
            .then(() => {
              this.getIssues()
            });
        }
        else {
          let row = this.state.issues[rowInfo.index];
          window.open('/issue/' + row.pk, '_self');
          console.log(row.pk)
        }
      }
    }
  }

  fileNameChanged = (e) => {
    this.setState({ fileNameChanged: true })
    let regexp = /^[a-zA-Z0-9\-]+$/;
    let check = e.target.value;
    if (check > '' && check.search(regexp) == -1) {
      return
    }
    else this.setState({ fileName: e.target.value, copyMsg: '', showMyMaps: false, mapSaved: false })
  }

  render() {
    return (
      <div id="issuesDiv">
        <div id="issueContent">
          {this.props.showOpen &&
            <div>
              {this.state.issues.length === 0 ?
                <div className="errorMsg">You haven't saved any Maps yet.</div>
                :
                <div id="openIssues">
                  <ReactTable
                    className="-highlight do-not-translate"
                    data={this.state.issues}
                    columns={openColumns}
                    loading={false}
                    showPaginationTop={false}
                    showPaginationBottom={false}
                    filterable={true}
                    defaultFilterMethod={this.filterCaseInsensitive}
                    getTdProps={this.checkIfRemove}
                    showPageSizeOptions={false}
                    defaultPageSize={this.state.issues.length}
                    id="openIssuesReactTable"
                  />
                </div>
              }
            </div>
          }
        </div>
        {this.state.msg !== null &&
          <div className="centerDiv" id="writeOver">
            {this.state.msg}
            <button
              className="mapOptionButton topMargin"
              onClick={this.setState({ msg: null })}
              type="button"
            >OK
            </button>
          </div>
        }
      </div>
    );
  }
}


export default IssueMapsSave;
