import { postLog } from '../utils/log';


export const loadAois = async (token, email, setAois, setSaveThisAoi, setReloadAois, setShowLogin, logout, issue = null) => {
    let headers = {
        "Content-Type": "application/json",
    };
    if (token) {
        headers["Authorization"] = `Token ${token}`;
    }
    if (issue !== undefined && issue !== null && issue.issue_type == "showaois") {
        issue = issue.pk;
        email = '';
    }
    issue = "";
    try {
        //return fetch(`http://localhost:8000/api/rssemailsubscription/?email=${email}&issue=${issue}`, { headers, })
        return fetch(`https://skytruth-alerts2.appspot.com/api/rssemailsubscription/?email=${email}&issue=${issue}`, { headers, })
            .then(res => {
                if (res.status < 500) {
                    return res.json().then(data => {
                        return { status: res.status, data };
                    })
                } else {
                    console.log("Server Error!");
                    throw res.status;
                }
            })
            .then(res => {
                if (res.status === 200) {
                    setAois(res.data);
                    setReloadAois(false);
                    setSaveThisAoi(false);
                    return res.data.results;
                } else if (res.status >= 400 && res.status < 500) {
                    if (res.status === 401) {
                        logout();
                    }
                    else
                        throw res.status;
                }
            })
    }
    catch (err) {
        logout();
    }
}


export const inactivateAoi = (token = null, id, setReloadAois = null) => {
    let headers = {
        "Content-Type": "application/json",
    };
    if (token) {
        headers["Authorization"] = `Token ${token}`;
    }
    //return fetch(`http://localhost:8000/api/inactivate_aoi/?id=${id}`, {headers, })
    return fetch(`https://skytruth-alerts2.appspot.com/api/inactivate_aoi/?id=${id}`, { headers, })
        .then(res => {
            if (res.status < 500) {
                return res.json().then(data => {
                    return { status: res.status, data };
                })
            } else {
                console.log("Server Error!");
                throw res;
            }
        })
        .then(res => {
            if (res.status === 200) {
                if (setReloadAois) {
                    setReloadAois(true);
                    return res.data.results;
                }
                else
                    return res.status;
            } else if (res.status >= 400 && res.status < 500) {
                console.log("Server Error!", res.status);
                throw res.status;
            }
        })
}

export const updateAoi = (token, id, passedGeom, area, email, setAois, setSaveThisAoi, setReloadAois) => {
    let wkt = passedGeom;
    let geom = wkt.substr(wkt.indexOf('POLYGON'), wkt.length)
    let headers = {
        "Content-Type": "application/json",
    };
    if (token) {
        headers["Authorization"] = `Token ${token}`;
    }
    let body = JSON.stringify({ id, geom, area });
    //return fetch("http://localhost:8000/api/update_aoi/", { headers, body, method: "POST" })
    return fetch("https://skytruth-alerts2.appspot.com/api/update_aoi/", { headers, body, method: "POST" })
        .then(res => {
            if (res.status < 500) {
                return res.json().then(data => {
                    return { status: res.status, data };
                })
            } else {
                console.log("Server Error!");
                throw res;
            }
        })
        .then(res => {
            if (res.status === 200) {
                loadAois(token, email, setAois, setSaveThisAoi, setReloadAois);
            } else if (res.status === 403 || res.status === 401) {
                dispatch({ type: "AUTHENTICATION_ERROR", data: res.data });
                throw res.data;
            } else {
                console.log("SaveAoi FAILED");
                throw res.data;
            }
        })
}


export const clickSubscriptionId = async (subId, token, setAoi, setAoiSelected, setShowAddAoi, setLimitToAoi, logout, limitToAoi, userId = -1) => {
    let headers = {
        "Content-Type": "application/json",
    };
    if (token) {
        headers["Authorization"] = `Token ${token}`;
    }

    //await fetch(`http://localhost:8000/api/rssemailsubscription/?id=${subId}`, {headers, })
    await fetch(`https://skytruth-alerts2.appspot.com/api/rssemailsubscription/?id=${subId}`, { headers, })
        .then(async res => {
            if (res.status < 500) {
                const data = await res.json();
                return { status: res.status, data };
            } else {
                console.log("Server Error!");
                throw res;
            }
        })
        .then(res => {
            if (res.status === 200) {
                if (res.data.length > 0) {
                    if (limitToAoi != null)
                        setLimitToAoi(limitToAoi);
                    setAoi(res.data[0]);
                    setAoiSelected(true);
                    if (setShowAddAoi) setShowAddAoi(false);
                    postLog(userId, 'clickSubscriptionId', res.data[0].aoidescr, res.data[0].id);
                }
            } else if (res.status >= 400 && res.status < 500) {
                if (res.status === 401)
                    logout();
                else {
                    dispatch({ type: "AUTHENTICATION_ERROR", data: res.data });
                    throw res.data;
                }
            }
        })
}

export const getAoi = (id) => {
    if (!id) return null;
    let headers = {
        "Content-Type": "application/json",
    };
    console.log('getaoi')
    //return fetch(`http://localhost:8000/api/${id}/getaoi/`, { headers, })
    return fetch(`https://skytruth-alerts2.appspot.com/api/${id}/getaoi/`, { headers, })
        .then(res => {
            if (res.status < 500) {
                return res.json().then(data => {
                    return { status: res.status, data };
                })
            } else {
                console.log("Server Error!");
                throw res;
            }
        })
        .then(res => {
            if (res.status === 200) {
                return res
            } else if (res.status >= 400 && res.status < 500) {
                dispatch({ type: "AUTHENTICATION_ERROR", data: res.data });
                throw res.data;
            }
        })
}
