export const loadRegion = async (token, id, setAois, setSaveThisAoi, exploreThisState) => {
    let headers = {
        "Content-Type": "application/json",
    };
    //return fetch(`http://localhost:8000/api/get_region/?id=${id}`, {headers, })
    await fetch(`https://skytruth-alerts2.appspot.com/api/get_region/?id=${id}`, {headers, })
    .then(async res => {
        if (res.status < 500) {
        return res.json().then(data => {
            return {status: res.status, data};
        })
        } else {
            console.log("Server Error!");
            throw res;
        }
    })
    .then(res => {
        if (res.status === 200) {
            let mapGeom = res.data[0].fields.the_geom;
            exploreThisState(mapGeom, 10); //res.data[0].fields.the_geom, 10);
            return res.data.region;
        } else if (res.status >= 400 && res.status < 500) {
            throw res.data;
        }
    })
}

export const loadRegionsByParent = (token, parent, setCounties) => {
    let headers = { 
        "Content-Type": "application/json",
        'Accept': 'application/json',
        //"Access-Control-Allow-Origin": "*",
       };
    //return fetch(`http://localhost:8000/api/get_regions_by_parent/?parent=${parent}`, {headers, })
    return fetch(`https://skytruth-alerts2.appspot.com/api/get_regions_by_parent/?parent=${parent}`, {headers, })
    .then(res => {
        if (res.status < 500) {
        return res.json().then(data => {
            return {status: res.status, data};
        })
        } else {
            console.log("Server Error!");
            throw res;
        }
    })
    .then(res => {
        if (res.status === 200) {
            let counties=[];
            res.data.map((region) => {
                let county = {value: region.pk, label: region.fields.name}
                counties.push(county);
            })    
            setCounties(counties);
            return counties;
        } else if (res.status >= 400 && res.status < 500) {
            throw res.data;
        }
    })
}

export const loadRegionSimpleGeom = (id) => {
    let headers = {
        "Content-Type": "application/json",
    };
    //return fetch(`http://localhost:8000/api/get_region/?id=${id}`, {headers, })
    return fetch(`https://skytruth-alerts2.appspot.com/api/get_region/?id=${id}`, {headers, })
    .then(res => {
        if (res.status < 500) {
        return res.json().then(data => {
            return {status: res.status, data};
        })
        } else {
            console.log("Server Error!");
            throw res;
        }
    })
    .then(res => {
        if (res.status === 200) {
            return res.data[0].fields.simple_geom;
        } else if (res.status >= 400 && res.status < 500) {
            throw res.data;
        }
    })
}
