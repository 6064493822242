import React from 'react';
import Bbox from '../components/Bbox';
import SelectAlerts from '../components/SelectAlerts';
import IssueMaps from '../components/IssueMaps';
// import SaveMap from '../components/SaveMap';


class Settings extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      returnMsg: '',
      userProfile: null,
      setting: null,
      message: null,
      theme: "light",
      firstLoginStep: null,
      accountSelection: null,
      help: null,
    };
  }

  componentDidMount() {
    if (this.props.showBbox) this.setState({ firstLoginStep: "bbox" })
    let storedTheme = localStorage.getItem("storedTheme");
    if (storedTheme === null || storedTheme === "") {
      storedTheme = "light";
      localStorage.setItem("storedTheme", storedTheme);
    }
    if (storedTheme !== this.state.theme)
      this.handleThemeChange();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.showBbox !== this.props.showBbox && this.props.showBbox) {
      if (this.props.userProfile.init_bbox == null) this.setState({ firstLoginStep: "bbox" })
      else this.setState({ setting: "bbox" });
    }
    if (prevProps.userProfile !== this.props.userProfile)
      if (this.props.userProfile.init_bbox === null)
        this.setState({ accountSelection: "settings", setting: "bbox" })
  }

  closeSelectedAlerts = (message) => {
    this.setState({ setting: null, message })
  }

  showInfo = () => {
    this.props.setHelpKey("settings")
  }

  handleSettingChange = (setting) => {
    if (setting === this.state.setting) setting = null;
    this.setState({ setting, message: null }, (
    ) => {
    })
  }

  handleHelpChange = (help) => {
    // if (help === this.state.help) help = null;
    this.setState({ help, message: null }, (
    ) => {
      if (this.state.help === "tutorial") {
        this.enableTurorial();
        this.props.setHelpKey(null);
      }
      if (this.state.help === "about") this.props.setHelpKey("intro");
      if (this.state.help === "guide") {
        this.props.setHelpKey(null);
      }
    })
  }

  handleAccountselection = (accountSelection) => {
    if (accountSelection === this.state.accountSelection) accountSelection = null;
    this.setState({ accountSelection, setting: null, message: null })
  }

  logout = () => {
    this.props.logout();
  }

  handleThemeChange = () => {
    let theme = this.state.theme;
    if (theme === "dark") theme = "light";
    else theme = "dark";
    // Get the root element
    var r = document.querySelector(':root');
    /* Keep for if you ever have to see the value before setting it
    // Get the styles (properties and values) for the root
       var rs = getComputedStyle(r);
       let bg = rs.getPropertyValue('--background-color');
    */
    let bg = "#ffffff";
    let fg = "#000000";
    let basemap = { type: 'style', id: 'dark' };
    if (theme === "light") {
      bg = "#000000";
      fg = "#ffffff";
      basemap = { type: 'style', id: 'light' };
    }
    r.style.setProperty('--background-color', bg);
    r.style.setProperty('--foreground-color', fg);
    r.style.setProperty('--callout-background-color', bg);
    this.props.setTheme(theme);
    this.props.setBasemap(basemap)
    this.setState({ theme }, () => {
      let storedTheme = localStorage.getItem("storedTheme");
      storedTheme = theme;
      localStorage.setItem("storedTheme", storedTheme);
    });
  }

  setNextStep = (nextStep) => {
    this.setState({ firstLoginStep: nextStep })
  }

  enableTurorial = () => {
    this.props.setTab(-1);
    this.props.setShowInitTutorial(true);
  }

  setSaveMap = (saveMap) => {
    this.props.setSaveMap(true)
  }
  render() {
    if (this.state.userProfile) {
    }
    return (
      <div className="sidebarPopup sidebarCallout settings">
        <div id="accountDiv">
          <div className='panelHeading'>Account</div>
          <div style={{ "marginBottom": "0.75rem" }}>
            <span style={{ "float": "left", "fontWeight": "bold", "marginRight": "0.625rem" }}>{this.props.user.username}</span>
            <span style={{ "float": "right" }}>
              <a onClick={this.logout}><i className="far fa-sign-out-alt large" data-rh="Logout"></i></a>
            </span>
          </div>
          <div style={{ "marginTop": "1rem", "textAlign": "left", "fontSize": "0.875rem" }}>
            <br /><br />
            <div>
              <a onClick={e => this.handleAccountselection("settings")}>
                <i className={this.state.accountSelection === "settings" ? "fas fa-chevron-down" : "fas fa-chevron-right"}></i>&nbsp;
                Settings
              </a>&nbsp;
            </div>
            {this.state.accountSelection === "settings" &&
              <div style={{ "marginTop": "0.5rem", "marginLeft": "1rem", "fontSize": "0.875rem" }}>
                <div >
                  <input
                    type="radio"
                    id="lighttheme"
                    name="theme"
                    value="light"
                    checked={this.state.theme === "light"}
                    onChange={this.handleThemeChange}
                  />
                  <label htmlFor="lighttheme" >
                    &nbsp;Light theme
                  </label>
                  &nbsp;&nbsp;&nbsp;&nbsp;
                  <input
                    type="radio"
                    id="darktheme"
                    name="theme"
                    value="dark"
                    checked={this.state.theme === "dark"}
                    onChange={this.handleThemeChange}
                  />
                  <label htmlFor="darktheme" >
                    &nbsp;Dark theme
                  </label>
                </div>

                <div style={{ "marginTop": "0.5rem", "textAlign": "left", "fontSize": "0.875rem" }}>
                  <div>
                    <a onClick={e => this.handleSettingChange("bbox")}>
                      <i className={this.state.setting === "bbox" ? "fas fa-chevron-down" : "fas fa-chevron-right"}></i>&nbsp;Starting map location</a>
                  </div>
                  {(this.state.setting === "bbox" || this.state.firstLoginStep === "bbox") &&
                    <div style={{ "marginLeft": "1.25rem" }}>
                      <Bbox
                        map={this.props.map}
                        setShowBbox={this.props.setShowBbox}
                        showBbox={this.props.showBbox}
                        email={this.props.email}
                        userId={this.props.userId}
                        token={this.props.token}
                        userProfile={this.props.userProfile}
                        setUserProfile={this.props.setUserProfile}
                        isAuthenticated={this.props.isAuthenticated}
                        setZoom={this.props.setZoom}
                        setMap={this.props.setMap}
                        setTab={this.props.setTab}
                        closeSelectedAlerts={this.closeSelectedAlerts}
                        theme={this.props.theme}
                        firstLoginStep={this.state.firstLoginStep}
                        setNextStep={this.setNextStep}
                      ></Bbox>
                    </div>
                  }
                </div>

                <div style={{ "marginTop": "0.5rem", "textAlign": "left", "fontSize": "0.875rem" }}>
                  <div>
                    <a onClick={e => this.handleSettingChange("alerts")}>
                      <i className={this.state.setting === "alerts" ? "fas fa-chevron-down" : "fas fa-chevron-right"}></i>&nbsp;Alerts</a>
                  </div>
                  {(this.state.setting === "alerts" || this.state.firstLoginStep === "alerts") &&
                    <div style={{ "marginLeft": "1.25rem", "width": "21.875rem" }}>
                      <SelectAlerts
                        email={this.props.email}
                        userId={this.props.id}
                        token={this.props.token}
                        userProfile={this.props.userProfile}
                        user={this.props.user}
                        setUserProfile={this.props.setUserProfile}
                        isAuthenticated={this.props.isAuthenticated}
                        closeSelectedAlerts={this.closeSelectedAlerts}
                        theme={this.props.theme}
                        firstLoginStep={this.state.firstLoginStep}
                        setNextStep={this.setNextStep}
                      ></SelectAlerts>
                    </div>
                  }
                </div>
              </div>
            }

            <div style={{ "marginTop": "1rem" }}>
              <a onClick={e => this.handleAccountselection("maps")}>
                <i className={this.state.accountSelection === "maps" ? "fas fa-chevron-down" : "fas fa-chevron-right"}></i>&nbsp;
                My Maps
              </a>&nbsp;
            </div>

            {this.state.accountSelection === "maps" &&
              <div style={{ "marginTop": "0.5rem", "marginLeft": "1rem", "fontSize": "0.875rem" }}>
                <IssueMaps
                  token={this.props.token}
                  aoi={this.props.aoi}
                  zoom={this.props.zoom}
                  limitToAoi={this.props.limitToAoi}
                  user={this.props.user}
                  map={this.props.map}
                  annotations={this.props.annotations}
                  setShowSave={this.props.setShowSave}
                  setShowOpen={this.props.setShowOpen}
                  showSave={false}
                  showOpen={true}
                  mapType={this.props.mapType}
                  satImage={this.props.satImage}
                  satImageType={this.props.satImageType}
                  cluster={this.props.cluster}
                  selectedFeeds={this.props.selectedFeeds}
                  alertsDateRange={this.props.alertsDateRange}
                  alertsKeyword={this.props.alertsKeyword}
                  mapviewName={this.props.mapviewName}
                  layers={this.props.layers}
                  includeLegend={this.props.includeLegend}
                  reportId={this.props.reportId}
                  setbacks={this.props.setbacks}
                  setIssue={this.props.setIssue}
                  issue={this.props.issue}
                  setLayerTimeline={this.props.setLayerTimeline}
                  compareImages={this.props.compareImages}
                  setReportId={this.props.setReportId}
                  setHelpKey={this.props.setHelpKey}
                  combinedImgs={this.props.combinedImgs}
                  theme={this.props.theme}
                ></IssueMaps>
              </div>
            }

            <div style={{ "marginTop": "1rem" }}>
              <a onClick={e => this.handleAccountselection("help")}>
                <i className={this.state.accountSelection === "help" ? "fas fa-chevron-down" : "fas fa-chevron-right"}></i>&nbsp;
                Help
              </a>&nbsp;
            </div>

            {this.state.accountSelection === "help" &&
              <div style={{ "marginTop": "0.5rem", "marginLeft": "1rem", "fontSize": "0.875rem" }}>
                <div>
                  <a onClick={e => this.handleHelpChange("about")}>
                    <i className={this.props.helpKey === "about" ? "fas fa-chevron-down" : "fas fa-chevron-right"}></i>
                    &nbsp;About Monitor
                  </a>
                </div>
                <div style={{ "marginTop": "0.5rem" }}>
                  <a onClick={e => this.handleHelpChange("tutorial")}>
                    <i className={this.props.showIntroTutorial === "tutorial" ? "fas fa-chevron-down" : "fas fa-chevron-right"}></i>
                    &nbsp;Introduction Tutorial
                  </a>
                </div>
                <div style={{ "marginTop": "0.5rem" }}>
                  <a onClick={e => this.handleHelpChange("guide")}
                    href="https://skytruth.org/section/account-settings/" target="_blank">
                    <i className={this.props.helpKey === "intro" ? "fas fa-chevron-down" : "fas fa-chevron-right"}></i>
                    &nbsp;Guide
                  </a>
                </div>
              </div>
            }

            <div style={{ "marginTop": "1rem", "textAlign": "center" }}>
              <a onClick={(e) => this.setSaveMap(true)}>
                <i className='far fa-star'></i>&nbsp;Save Map
              </a>
            </div>

            {this.state.message !== null &&
              <div style={{ "marginTop": "0.75rem", "fontSize": "0.875rem" }}>
                {this.state.message}
              </div>
            }

          </div>

        </div>
      </div>
    )

  }

}


export default Settings;
