import WKT from 'terraformer-wkt-parser';


export const geojsonToWkt = (geoJson) => {
    let ret = null;
    try {
        ret = WKT.convert(geoJson);
    }
    catch (err) {
        console.error(err.message);
      }
    return ret;
}

/*
    See: https://developers.google.com/maps/documentation/javascript/examples/polygon-hole
    The returned array for a MultiPolygon is an array of arrays. It is not an array inside of another array.
*/
export const loadAoiPolygon = (myGeom) => {
    let returnCoords = [];
    let point;
    var convertCoords = function (coords) {
        let theseCoords = [];
        let point;
        coords.forEach((coord) => {
            if (Array.isArray(coord) && coord.length === 2 && !isNaN(coord[0]) && !isNaN(coord[1])) {
                point = { lat: coord[1], lng: coord[0] }
                theseCoords.push(point);
            }
            else {
                convertCoords(coord)
            }
        })
        returnCoords.push(theseCoords);
    };

    myGeom = myGeom.replace('SRID=4326;', '')
    let geomType = "Polygon";
    if (myGeom.indexOf('MULTIPOLYGON') > -1) geomType = "MultiPolygon"
    else if (myGeom.indexOf('MULTILINESTRING') > -1) geomType = "MultiLineString"
    else if (myGeom.indexOf('LINESTRING') > -1) geomType = "LineString";
    let it = WKT.parse(myGeom);
    if (["Polygon"].includes(geomType))
        it.coordinates[0].forEach((coord) => {
            point = { lat: coord[1], lng: coord[0] }
            returnCoords.push(point);
        })
    if (["LineString"].includes(geomType)) {
        it.coordinates.forEach((coord) => {
            point = { lat: coord[1], lng: coord[0] }
            returnCoords.push(point);
        })
    }
    if (["MultiPolygon","MultiLineString"].includes(geomType)) {
        it.coordinates.forEach((coord) => {
            convertCoords(coord);
        })
    }
    return returnCoords;
}

export const degrees2meters = function (lon, lat) {
    var x = lon * 20037508.34 / 180;
    var y = Math.log(Math.tan((90 + lat) * Math.PI / 360)) / (Math.PI / 180);
    y = y * 20037508.34 / 180;
    return [x, y]
}

/**
 * Takes a string in wkt format for polygon or multipolygon and returns an array
 *  of strings represeting the bounding box of the shape
 * @param {string} wktString a string represetning a polygon or multipolygon 
 *  in wkt format https://libgeos.org/specifications/wkt/
 * @returns {array<string>} [maxLng, maxLat, minLng, minLat]
 */
export function getBoundingBox(wktString){
    const regex = /\(\((.*?)\)\)/;
    const match = wktString.match(regex);
    if (!match) {
      return [];
    }
  
    const coordinatePairs = match[1].split(',').map(pair => pair.trim());
    
    let minLat = Infinity, maxLat = -Infinity;
    let minLng = Infinity, maxLng = -Infinity;
    
    coordinatePairs.forEach(pair => {
      const [lngStr, latStr] = pair.split(/\s+/);
      const lng = parseFloat(lngStr);
      const lat = parseFloat(latStr);
      
      if (lat < minLat) minLat = lat;
      if (lat > maxLat) maxLat = lat;
      if (lng < minLng) minLng = lng;
      if (lng > maxLng) maxLng = lng;
    });
    
    return [maxLng, maxLat, minLng, minLat];
  }

/* This may be useful in the future. Keep!
export const getCentroid = function (geom) {
    console.log(geom)
    let bounds = new google.maps.LatLngBounds();
    var i;

    // // The Bermuda Triangle
    // var polygonCoords = [
    //   new google.maps.LatLng(25.774252, -80.190262),
    //   new google.maps.LatLng(18.466465, -66.118292),
    //   new google.maps.LatLng(32.321384, -64.757370),
    //   new google.maps.LatLng(25.774252, -80.190262)
    // ];

    if (geom.type === "Polygon" || geom.type === "MultiLineString")
        geom.coordinates.forEach((coords) => {
            coords.forEach((point) => {
                bounds.extend(new google.maps.LatLng(point[1], point[0]))
            })
        })
    if (geom.type === "MultiPolygon")
        geom.coordinates.forEach((coords0) => {
            coords0.forEach((coords1) => {
                coords1.forEach((point) => {
                    bounds.extend(new google.maps.LatLng(point[1], point[0]))
                })
            })
        })
    // The Center of the Bermuda Triangle - (25.3939245, -72.473816)
    console.log(bounds.getCenter());
    return bounds.getCenter()
}
*/
