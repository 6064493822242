import React, { Component } from "react";
import { connect } from "react-redux";
import * as auth from "../actions/auth";


class Register extends Component {

  constructor(props) {
    super(props);
    this.state = {
      username: "",
      password: "",
      first_name: "",
      last_name: "",
      email: "",
      returnMsg: '',
      errorMsg: '',
      showForm: true,
      screen: '2',
      apiMsg: null,
    };
  }


  onSubmit = e => {
    e.preventDefault();
    this.setState({ errorMsg: '', apiMsg: 'Registering...' })
    if (this.state.email === "")
      this.setState({ errorMsg: "Email is required.", apiMsg: null })
    else if (this.state.password !== this.state.password2)
      this.setState({ errorMsg: "Passwords must match.", apiMsg: null })
    else if (this.state.username.includes(' '))
      this.setState({ errorMsg: "User name cannot contain a space.", apiMsg: null })
    else {
      this.props.register(this.state.username, this.state.password, this.state.first_name, this.state.last_name, this.state.email)
        .then(res => {
          if (res.status === "OK") {
            this.setState({ screen: '3', returnMsg: res.data, apiMsg: null });
          }
          else
            this.setState({ errorMsg: res.data, apiMsg: null })
        });
    }
  }

  closeModal = () => {
    this.props.setShowRegister(false);
    this.props.setTab(-1);
    this.setState({ showForm: true })
    this.setState({ screen: '1' })
  }

  login = () => {
    this.props.setShowRegister(false);
    this.props.setShowLogin(true);
    this.setState({ showForm: true })
    this.setState({ screen: '1' })
  }

  whichScreenButtonChanged = () => {
    if (this.state.screen === '1') this.setState({ screen: '2' });
    if (this.state.screen === '2') this.setState({ screen: '1' });
  }


  render() {
    const customStyles = {
      content: {
        top: 'auto',
        left: 'auto',
        right: 'auto',
        bottom: 'auto',
        height: '100vh',
        width: '92vw',
        transform: 'translate(0vw, 0vh)',
      }
    }

    const fieldStyle = {
      width: '18.75rem',
    }


    return (
      <div id="register">
        <a onClick={this.closeModal} ><i className="far fa-times-circle modalClose"></i></a>
        <br />
        <div className="title" style={{ "textAlign": "center" }}><b>
          Register for a new SkyTruth Monitor account</b>
        </div>
        <form onSubmit={this.onSubmit} className={this.state.showForm === true ? '' : 'hidden'} >
          <div id="register1" className={this.state.screen === '1' ? '' : 'hidden'}>
            A new account gives you full access to this application, allowing you to
            view satellite imagery, monitor your own list of Areas of Interest (AOIs) and subscribe to
            alerts occurring in those areas.
            <br /><br />
            By proceeding, you acknowledge you’ve read and agree with &nbsp;
            <a href="https://skytruth.org/privacy-policy/" target="_blank">SkyTruth's Privacy Policy</a> and
            &nbsp;<a href="https://skytruth.org/terms-of-service//" target="_blank">Terms of Service</a>.
            <br /><br />
          </div>
          <div id="register2" className={this.state.screen === '2' ? '' : 'hidden'}>
            {this.props.errors.length > 0 && (
              <ul className='errorMsg'>
                {this.props.errors.map(error => (
                  <li key={error.field}>{error.message}</li>
                ))}
              </ul>
            )}
            <div className={this.state.errorMsg === '' ? 'hidden' : 'errorMsg'}>{this.state.errorMsg}</div>
            <div>
              <div></div><br />
              <table>
                <tbody style={{ "margin": "0.25rem 0rem 0rem 0rem" }}>
                  <tr>
                    <td>&nbsp;</td>
                    <td>
                      <label className="loginLabel" htmlFor="username">User Id:&nbsp;</label>
                    </td>
                    <td>
                      <input
                        style={fieldStyle}
                        autoComplete="false"
                        type="text"
                        id="username"
                        autoFocus
                        onChange={e => this.setState({ username: e.target.value })} />
                    </td>
                  </tr>
                  <tr>
                    <td>&nbsp;</td>
                    <td>
                      <label className="loginLabel" htmlFor="password">Password:&nbsp;</label>
                    </td>
                    <td>
                      <input
                        type="password" id="password"
                        autoComplete="current-password"
                        onChange={e => this.setState({ password: e.target.value })} />
                    </td>
                  </tr>
                  <tr>
                    <td>&nbsp;</td>
                    <td>
                      <label className="loginLabel" htmlFor="password2">Retype Password:&nbsp;</label>
                    </td>
                    <td>
                      <input
                        type="password" id="password2"
                        autoComplete="current-password"
                        onChange={e => this.setState({ password2: e.target.value })} />
                    </td>
                  </tr>
                  <tr>
                    <td>&nbsp;</td>
                    <td>
                      <label className="loginLabel" htmlFor="email">Email:&nbsp;</label>
                    </td>
                    <td>
                      <input
                        style={fieldStyle}
                        type="text" id="email"
                        onChange={e => this.setState({ email: e.target.value })} />
                    </td>
                  </tr>
                  <tr>
                    <td>&nbsp;</td>
                    <td>&nbsp;</td>
                    <td>
                      <button type="submit" style={{ "marginTop": "0.25rem" }}>Register</button>
                      {this.state.apiMsg !== null &&
                        <span className="blink_me">&nbsp;{this.state.apiMsg}</span>
                      }
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div className="returnMsg">{this.state.returnMsg}</div>
          </div>
          <div id="register3" className={this.state.screen === '3' ? '' : 'hidden'}>
            Registration successful. An email was sent to {this.state.email} with a link
            to verify you own this email.
          </div>
        </form>
      </div>
    )
  }
}

const mapStateToProps = state => {
  let errors = [];
  if (state.auth.errors) {
    errors = Object.keys(state.auth.errors).map(field => {
      return { field, message: state.auth.errors[field] };
    });
  }
  return {
    errors,
    isAuthenticated: state.auth.isAuthenticated
  };
}

const mapDispatchToProps = dispatch => {
  return {
    register: (username, password, first_name, last_name, email) =>
      dispatch(auth.register(username, password, first_name, last_name, email)),
  };
}


export default connect(mapStateToProps, mapDispatchToProps)(Register);
