import React from 'react';
import Modal from 'react-modal';
import * as auth from "../actions/auth";


const customStyles = {
    content: {
        top: '40%',
        left: '30%',
        right: 'auto',
        bottom: 'auto',
        marginRight: 'auto',
        height: 'auto',
        transform: 'translate(-50%, -50%)'
    }
};


class ForgotUserId extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            modalIsOpen: true,
            emailForForgottenUserId: '',
            showButton: false,
            message: null,
        };
        this.openModal = this.openModal.bind(this);
        this.afterOpenModal = this.afterOpenModal.bind(this);
        this.closeModal = this.closeModal.bind(this);
    }

    openModal() {
        this.setState({ modalIsOpen: true });
    }

    afterOpenModal() {
    }

    closeModal() {
        this.props.closeForgotUserId();
        this.setState({ modalIsOpen: false });
    }

    updateEmailForForgottenUserId = (e) => {
        this.setState({ emailForForgottenUserId: e.target.value, message: null }, () => {
            if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(this.state.emailForForgottenUserId)) {
                this.setState({ showButton: true })
            }
            else
                this.setState({ showButton: false })
        })
    }

    emailUserId = () => {
        auth.getIdFromEmail(this.state.emailForForgottenUserId, true)
            .then(res => {
                if (res.data.status === "ok")
                    this.setState({ showButton: false, message: "If this email exists in SkyTruth Monitor, we emailed the User Id to it" })
            });
    }

    render() {
        return (
            <Modal
                isOpen={this.state.modalIsOpen}
                onAfterOpen={this.afterOpenModal}
                onRequestClose={this.closeModal}
                style={customStyles}
                contentLabel="Example Modal"
                ariaHideApp={false}
                setAppElement={'#yourAppElement'}
            >
                <a onClick={this.closeModal} ><i className="far fa-times-circle modalClose"></i></a>
                <h3>Forgot User Id</h3>
                Email: <input type="text" value={this.state.emailForForgottenUserId}
                    size="50" onChange={this.updateEmailForForgottenUserId}></input>&nbsp;
                {this.state.showButton &&
                    <div>
                        <br />
                        <button onClick={this.emailUserId}>Email my User Id</button>
                    </div>
                }
                {this.state.message &&
                    <div><br />{this.state.message}</div>
                }
            </Modal>
        );
    }
}


export default ForgotUserId;
