import { connect } from 'react-redux';
import PublicIssues from '../components/PublicIssues';
import { setShowLogin, setShowRegister, setUserProfile } from '../actions/map'; 
import {  } from '../actions/auth';

const mapStateToProps = state => ({
  isAuthenticated: state.auth.isAuthenticated,
  showRegister: state.mapreducers.showRegister,
  // showStartHelp: state.mapreducers.showStartHelp,
  showLogin: state.mapreducers.showLogin,
  token: state.auth.token,
  user: state.auth.user,
  userProfile: state.mapreducers.userProfile,
  username: state.auth.user.username ? state.auth.user.username : '',
  showNav: state.mapreducers.showNav,
});

const mapDispatchToProps = dispatch => ({
  setShowLogin: (showLogin) => {
    dispatch(setShowLogin(showLogin))
  },
  setShowRegister: (showRegister) => {
    dispatch(setShowRegister(showRegister))
  },
  setUserProfile: (userProfile) => {
    dispatch(setUserProfile(userProfile))
  },
  // setShowNav: (showNav) => {
  //   dispatch(setShowNav(showNav))
  // },
});

export default connect(mapStateToProps, mapDispatchToProps)(PublicIssues);
