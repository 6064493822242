import React, { Suspense } from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { connect } from 'react-redux';
import NotFoundPage from '../components/NotFoundPage';
const Header = React.lazy(() => import('../components/Header'));
const Footer = React.lazy(() => import('../components/Footer'));
const MyGoogleMap = React.lazy(() => import('../containers/ContainerGoogleMap'));
import Login from "../components/Login";
import PwReset from "../components/PwReset";
import Register from "../components/Register";
import Unsubscribe from "../components/Unsubscribe";
import VerifyAcct from "../components/VerifyAcct";
import { setShowLogin, setShowPwChange } from '../actions/map';
import MySettings from "../containers/ContainerSettings";
import MyPublicIssues from "../containers/ContainerPublicIssues";


class AppRouter extends React.Component {
    constructor(props) {
        super(props);
    }
    render() {
        return (
            <BrowserRouter>
                <div className="container">
                    <Suspense fallback={<div></div>}>
                        <Header {...this.props} />
                    </Suspense>
                    <Suspense fallback={<div></div>}>
                        <Switch>
                            <Route path="/map/:issueid" component={MyGoogleMap} exact={true} />
                            <Route path="/settings" component={MySettings} exact={true} />
                            <Route path="/settings/:id" component={MySettings} />
                            <Route path="/report/:reportid" component={MyGoogleMap} />
                            <Route path="/sat/:sat" component={MyGoogleMap} />
                            <Route path="/issues" component={MyPublicIssues} exact={true} />
                            <Route path="/issue/:issueid" component={MyGoogleMap} />
                            <Route path="/aoi/:aoiid" component={MyGoogleMap} />
                            {/* <Route path="/scene/:sceneid" component={MyGoogleMap} /> */}
                            <Route path="/cd/:cdid" component={MyGoogleMap} />
                            <Route exact path="/unsubscribe/:id" component={Unsubscribe} />
                            <Route path="/start/:start" component={MyGoogleMap} />
                            {/* <Route path="/about" component={HelpPage} />
                        <Route path="/contact" component={HelpPage} /> */}
                            <Route exact path="/login" component={Login} />
                            <Route exact path="/register" component={Register} />
                            <Route exact path="/reset" component={PwReset} />
                            <Route path="/verifyacct/:taskid" component={VerifyAcct} />
                            <Route path="/uploadtostorage" component={MyGoogleMap} />
                            <Route path="/" component={MyGoogleMap} exact={true} />
                            <Route component={NotFoundPage} />
                            {/* <Route path="/:helpid" component={MyGoogleMap} /> */}
                        </Switch>
                    </Suspense>
                    <Suspense fallback={<div></div>}>
                        <Footer />
                    </Suspense>
                </div>
            </BrowserRouter>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        //Auth
        isAuthenticated: state.auth.isAuthenticated,
        user: state.auth.user,

        // Map Reducer
        issue: state.mapreducers.issue,
        layerTimeline: state.mapreducers.layerTimeline,
        planetKey: state.mapreducers.userProfile?.planet_key,
        theme: state.mapreducers.theme,  
    }
}

const mapDispatchToProps = dispatch => {
    return {
        setShowLogin: (showLogin) => {
            dispatch(setShowLogin(showLogin))
        },
        setShowPwChange: (showPwChange) => {
            dispatch(setShowPwChange(showPwChange))
        }
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(AppRouter);
