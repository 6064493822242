/** File to hold utility functions realted to loggin analytics events to Heap */

/**
 * Log user adding satellite image to the map to heap
 * @param {string} imgType String indicating the satellite and potentially type of product. e.g. SI_S2SR__NDVI, S2
 * @param {array[string]} images Array of all the satellite images loaded to the map
 * @param {object} bounds The map bounds object returned from google maps
 */
export function logAddSatelliteImage(imgType, images, bounds) {
  const totalImages = images.length;
  const currentImage = images[images.length - 1];
  const [product, satellite] = getSatelliteProduct(imgType, currentImage);
  const northEast = bounds.getNorthEast();
  const southWest = bounds.getSouthWest();
  const boundingBox = `${northEast.lng()},${northEast.lat()},${southWest.lng()},${southWest.lat()}`;

  const data = {
    satellite,
    product, //ndvi, rbg composite, image ...
    boundingBox,
    totalImages,
  };

  window.heap.track('Monitor Add Satellite Image', data);
}

/**
 * Log a user adding a map layer to the map
 * @param {string} layerName Name of the layer
 */
export function logAddMapLayer(layerName) {
  const EVENT_NAME = 'Monitor Select Layer';
  window.heap.track(EVENT_NAME, {
    value: layerName,
  });
}

/**
 * Log a user subscribing to email alerts
 * @param {string} original stringified array of alert indecies, as returned from the database, that indicate a
 *  users subscription status before submitting changes
 * @param {array[number]} selected Array of alert indecies indicating the state of  user subscribed alerts after
 *  submitting changes
 * @param {array[object]} feed the array of all possible alerts subscriptions with metadata, incuding the referenced
 *  index in the other parameters and a human readable name
 */
export function logEmailSubscription(original, selected, feed) {
  const EVENT_NAME = 'Monitor Change Email Subscription';
  original = JSON.parse(original);
  const orignalSet = new Set(original.map((alert) => +alert)); // subscription ID's from the DB are strings, converting for comparrison
  const selectedSet = new Set(selected);
  const feedMap = feed.reduce((feedMap, ele) => {
    feedMap[ele.pk] = ele.fields.name;
    return feedMap;
  }, {});

  const unsubscribe = orignalSet.difference(selectedSet);
  const subscribe = selectedSet.difference(orignalSet);

  unsubscribe.forEach((alert) => {
    const alertName = feedMap[alert];
    window.heap.track(EVENT_NAME, {
      action: 'unsubscribe',
      value: alertName,
    });
  });

  subscribe.forEach((alert) => {
    const alertName = feedMap[alert];
    window.heap.track(EVENT_NAME, {
      action: 'subscribe',
      value: alertName,
    });
  });
}

/**
 * Log a user engaging with an Aoi. Events include creating a new aoi, loading an aoi on the map, deleting an aoi.
 * @param {string} action create delete, load_on_map, etc. See Heap to see all events currently fired
 * @param {string} type Type of aoi. for newly created Aoi's this is polygione, uploaded, rectangle, etc. For load
 *  and delete AOI we just use custom or predefined because we don't keep creation type in the DB
 * @param {array[number]} boundingBox The bounding box of the AOI
 */
export function logAOIEngaged(action, type, boundingBox) {
  const EVENT_NAME = 'Monitor AOI Engaged';

  // Stringify and format bounding box
  let bb = JSON.stringify(boundingBox);
  bb = bb.substring(1, bb.length - 1);
  const data = {
    action, // create, delete, load on map
    type, //rectangle, polygone, upload
    boundingBox: bb,
  };

  window.heap.track(EVENT_NAME, data);
}

export function logAccountCreated() {
  const EVENT_NAME = 'Monitor Account Created';
  window.heap.track(EVENT_NAME);
}

/**
 * Log a user engaging with the sidebar menu
 * @param {string} menuName Name of the menu
 * @param {string} action Action the user took in the menu. e.g. Close, Open
 */
export function logMenuEngaged(menuName, action) {
  const EVENT_NAME = 'Monitor Menu Engaged';
  window.heap.track(EVENT_NAME, {
    action,
    value: menuName,
  });
}

/**
 * function to parse the satelite image and imageType to determine which satelite and which image product
 * are being represented by the img and imgType
 * @param {string} imgType String indicating the satellite and potentially type of product. e.g. SI_S2SR__NDVI, S2
 * @param {string} image The name of the satellite image
 * @returns {array[string]} A tuple containing the satellite product - image, NDVI, etc - and the satellite
 *  - s1, Planet, etc.
 */
function getSatelliteProduct(imgType, image) {
  // Special case for 'Other Satellite Derived Products' menu.
  if (imgType === 'other') {
    return ['RBG composite', 'S1'];
  }

  let satellite = '';
  let product = 'Image';
  const splitImg = imgType.split('_');

  // Spectral index with naming convention like SI_S2SR__NDVI
  if (imgType.startsWith('SI')) {
    product = splitImg[3];
    satellite = splitImg[1];
  } else {
    // Strait up satellite image
    satellite = splitImg[0];
  }

  // Align diverging satellite abreviations and get sat number if Landsat
  if (satellite.includes('S1')) {
    satellite = 'S1';
  } else if (satellite.includes('S2')) {
    satellite = 'S2';
  } else if (satellite.includes('LANDSAT')) {
    let version = '';
    const imgIdentifiers = image.split('/');
    if (imgIdentifiers[1] === 'LC08') {
      version = '8';
    } else if (imgIdentifiers[1] === 'LC09') {
      version = '9';
    }
    satellite = 'LANDSAT' + version;
  } else if (satellite === 'PSScene') {
    satellite = 'Planet';
    product = 'Daily Image';
  } else if (satellite === 'SkySatScene') {
    satellite = 'SkySat';
    product = 'Daily Image';
  } else if (satellite === 'Planet') {
    //These are planet monthly basemaps
    splitImg.shift();
    product = splitImg.join('_');
  }

  return [product, satellite];
}
