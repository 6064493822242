export const selectMapLayersStyle = {
  container: (provided) => ({
    ...provided,
    background: 'var(--background-color)',
    color: 'var(--foreground-color)',
    width: '100%',
  }),
  control: (provided) => ({
    ...provided,
    background: 'var(--background-color)',
    color: 'var(--foreground-color)',
    // match with the menu
    borderRadius: 5,
    // Overwrittes the different states of border
    borderColor: 'var(--foreground-color)',
    // Removes weird border around container
    boxShadow: null,
    '&:hover': {
      color: 'blue',
      // Overwrittes the different states of border
      borderColor: 'var(--foreground-color)',
    },
    minHeight: '1rem',
  }),
  input: (provided) => ({
    ...provided,
    color: 'var(--foreground-color)',
  }),
  menu: (provided) => ({
    ...provided,
    background: 'var(--background-color)',
    borderRadius: 0,
    // kill the gap
    marginTop: 0,
  }),
  menuList: (provided) => ({
    ...provided,
    background: 'var(--background-color)',
    color: 'var(--foreground-color)',
    // kill the white space on first and last option
    padding: 0,
    marginTop: -3,
    lineHeight: '0.375rem',
    borderBottom: '0.0625rem solid var(--foreground-color)',
    borderTop: '0.0625rem solid var(--foreground-color)',
    borderLeft: '0.0625rem solid var(--foreground-color)',
    maxHeight: '27.25rem',
    overflowY: 'visible',
    overflowX: 'hidden',
  }),
  option: (provided, state) => ({
    ...provided,
    color: state.value === 'category' ? 'var(--background-color)' : 'var(--foreground-color)',
    background: state.value === 'category' ? 'gray' : 'var(--background-color)',
    '&:hover': {
      background: 'gray',
      opacity: 1.0,
      // Overwrittes the different states of border
      borderColor: 'var(--foreground-color)',
    },
  }),
  placeholder: (provided) => ({
    ...provided,
    color: 'var(--foreground-color)',
  }),
  singleValue: (provided) => ({
    ...provided,
    color: 'var(--foreground-color)',
  }),
};

/* General Select style used by default */
export const selectCustomStyles = {
  container: (provided) => ({
    ...provided,
    background: 'var(--background-color)',
    color: 'var(--foreground-color)',
    width: '100%',
  }),
  control: (provided) => ({
    ...provided,
    background: 'var(--background-color)',
    color: 'var(--foreground-color)',
    borderRadius: 5,
    borderColor: 'var(--foreground-color)',
    boxShadow: null,
    '&:hover': {
      color: 'blue',
      borderColor: 'var(--foreground-color)',
    },
    minHeight: '1rem',
  }),
  input: (provided) => ({
    ...provided,
    color: 'var(--foreground-color)',
  }),
  menu: (provided) => ({
    ...provided,
    background: 'var(--background-color)',
    borderRadius: 0,
    // kill the gap
    marginTop: 0,
  }),
  menuList: (provided) => ({
    ...provided,
    background: 'var(--background-color)',
    color: 'var(--foreground-color)',
    // kill the white space on first and last option
    padding: 0,
    marginTop: -3,
    lineHeight: '0.375rem',
    borderBottom: '0.0625rem solid var(--foreground-color)',
    borderTop: '0.0625rem solid var(--foreground-color)',
    borderLeft: '0.0625rem solid var(--foreground-color)',
    maxHeight: '27.25rem',
    overflowY: 'visible',
    overflowX: 'hidden',
  }),
  option: (provided) => ({
    ...provided,
    color: 'var(--foreground-color)',
    background: 'var(--background-color)',
    '&:hover': {
      background: 'gray',
      opacity: 1.0,
      // Overwrittes the different states of border
      borderColor: 'var(--foreground-color)',
    },
  }),
  placeholder: (provided) => ({
    ...provided,
    color: 'var(--foreground-color)',
  }),
  singleValue: (provided) => ({
    ...provided,
    color: 'var(--foreground-color)',
  }),
};

import * as mouseOvers from '../utils/mouseoverMsgs';

export const markerGrps = [
  {
    icon: '/images/alertIcons/red-circle-border-n-24.png',
    descr: 'NRC',
    src: [1],
    mouseOver: mouseOvers.nrc_tip,
    secure: false,
  },
  {
    icon: '/images/alertIcons/green-square-border-og-24.png',
    descr: 'Drilling Permit Approved',
    src: [4, 8],
    mouseOver: mouseOvers.drilling_approved_tip,
    secure: false,
  },
  {
    icon: '/images/alertIcons/blue-square-border-og-24.png',
    descr: 'Spud Reported',
    src: [5],
    mouseOver: mouseOvers.drilling_spud_tip,
    secure: false,
  },
  {
    icon: '/images/alertIcons/yellow-square-border-og-24.png',
    descr: 'Violation Cited',
    src: [9],
    mouseOver: mouseOvers.drilling_violations_tip,
    secure: false,
  },
  {
    icon: '/images/alertIcons/red-square-border-p-24.png',
    descr: 'Pollution',
    src: [1060, 2001, 1080],
    mouseOver: mouseOvers.pollution_tip,
    secure: false,
  },
  {
    icon: '/images/alertIcons/st_white_swoosh_on_violations.png',
    descr: 'Vessels of Concern',
    src: [10102],
    mouseOver: mouseOvers.custom_10102,
    secure: true,
  },
];

export const operands = [
  { value: '>', label: 'is greater than' },
  { value: '<', label: 'is less than' },
];

export const andors = [
  { value: 'and', label: 'and' },
  { value: 'or', label: 'or' },
];

export const valueIs = [
  { value: 'pcnt-increase', label: '% increase' },
  { value: 'pcnt-decrease', label: '% decrease' },
  { value: 'pcnt-change', label: '% incr or decr' },
];

export const countries = [
  { label: 'Afghanistan', value: '60.53, 29.32, 75.16, 38.49' },
  { label: 'Albania', value: '19.3, 39.62, 21.02, 42.69' },
  { label: 'Algeria', value: '-8.68, 19.06, 12.0, 37.12' },
  { label: 'Angola', value: '11.64, -17.93, 24.08, -4.44' },
  { label: 'Antarctica', value: '-180.0, -90.0, 180.0, -63.27' },
  { label: 'Argentina', value: '-73.42, -55.25, -53.63, -21.83' },
  { label: 'Armenia', value: '43.58, 38.74, 46.51, 41.25' },
  { label: 'Australia', value: '113.34, -43.63, 153.57, -10.67' },
  { label: 'Austria', value: '9.48, 46.43, 16.98, 49.04' },
  { label: 'Azerbaijan', value: '44.79, 38.27, 50.39, 41.86' },
  { label: 'Bahamas', value: '-78.98, 23.71, -77.0, 27.04' },
  { label: 'Bangladesh', value: '88.08, 20.67, 92.67, 26.45' },
  { label: 'Belarus', value: '23.2, 51.32, 32.69, 56.17' },
  { label: 'Belgium', value: '2.51, 49.53, 6.16, 51.48' },
  { label: 'Belize', value: '-89.23, 15.89, -88.11, 18.5' },
  { label: 'Benin', value: '0.77, 6.14, 3.8, 12.24' },
  { label: 'Bhutan', value: '88.81, 26.72, 92.1, 28.3' },
  { label: 'Bolivia', value: '-69.59, -22.87, -57.5, -9.76' },
  { label: 'Bosnia and Herzegovina', value: '15.75, 42.65, 19.6, 45.23' },
  { label: 'Botswana', value: '19.9, -26.83, 29.43, -17.66' },
  { label: 'Brazil', value: '-73.99, -33.77, -34.73, 5.24' },
  { label: 'Brunei', value: '114.2, 4.01, 115.45, 5.45' },
  { label: 'Bulgaria', value: '22.38, 41.23, 28.56, 44.23' },
  { label: 'Burkina Faso', value: '-5.47, 9.61, 2.18, 15.12' },
  { label: 'Burundi', value: '29.02, -4.5, 30.75, -2.35' },
  { label: 'Cambodia', value: '102.35, 10.49, 107.61, 14.57' },
  { label: 'Cameroon', value: '8.49, 1.73, 16.01, 12.86' },
  { label: 'Canada', value: '-141.0, 41.68, -52.65, 73.23' },
  { label: 'Central African Republic', value: '14.46, 2.27, 27.37, 11.14' },
  { label: 'Chad', value: '13.54, 7.42, 23.89, 23.41' },
  { label: 'Chile', value: '-75.64, -55.61, -66.96, -17.58' },
  { label: 'China', value: '73.68, 18.2, 135.03, 53.46' },
  { label: 'Congo (Brazzaville)', value: '11.09, -5.04, 18.45, 3.73' },
  { label: 'Congo (Kinshasa)', value: '12.18, -13.26, 31.17, 5.26' },
  { label: 'Colombia', value: '-78.99, -4.3, -66.88, 12.44' },
  { label: 'Costa Rica', value: '-85.94, 8.23, -82.55, 11.22' },
  { label: 'Croatia', value: '13.66, 42.48, 19.39, 46.5' },
  { label: 'Cuba', value: '-84.97, 19.86, -74.18, 23.19' },
  { label: 'Cyprus', value: '32.26, 34.57, 34.0, 35.17' },
  { label: 'Czech Republic', value: '12.24, 48.56, 18.85, 51.12' },
  { label: 'Denmark', value: '8.09, 54.8, 12.69, 57.73' },
  { label: 'Djibouti', value: '41.66, 10.93, 43.32, 12.7' },
  { label: 'Dominican Republic', value: '-71.95, 17.6, -68.32, 19.88' },
  { label: 'East Timor', value: '124.97, -9.39, 127.34, -8.27' },
  { label: 'Ecuador', value: '-80.97, -4.96, -75.23, 1.38' },
  { label: 'Egypt', value: '24.7, 22.0, 36.87, 31.59' },
  { label: 'El Salvador', value: '-90.1, 13.15, -87.72, 14.42' },
  { label: 'Equatorial Guinea', value: '9.31, 1.01, 11.29, 2.28' },
  { label: 'Eritrea', value: '36.32, 12.46, 43.08, 18.0' },
  { label: 'Estonia', value: '23.34, 57.47, 28.13, 59.61' },
  { label: 'Ethiopia', value: '32.95, 3.42, 47.79, 14.96' },
  { label: 'Falkland Islands', value: '-61.2, -52.3, -57.75, -51.1' },
  { label: 'Fiji', value: '-180.0, -18.29, 180.0, -16.02' },
  { label: 'Finland', value: '20.65, 59.85, 31.52, 70.16' },
  { label: 'France', value: '-5.0, 42.5, 9.56, 51.15' },
  { label: 'French Southern Territories', value: '68.72, -49.78, 70.56, -48.63' },
  { label: 'Gabon', value: '8.8, -3.98, 14.43, 2.33' },
  { label: 'Gambia', value: '-16.84, 13.13, -13.84, 13.88' },
  { label: 'Georgia', value: '39.96, 41.06, 46.64, 43.55' },
  { label: 'Germany', value: '5.99, 47.3, 15.02, 54.98' },
  { label: 'Ghana', value: '-3.24, 4.71, 1.06, 11.1' },
  { label: 'Guinea', value: '-15.13, 7.31, -7.83, 12.59' },
  { label: 'Guinea Bissau', value: '-16.68, 11.04, -13.7, 12.63' },
  { label: 'Greece', value: '20.15, 34.92, 26.6, 41.83' },
  { label: 'Greenland', value: '-73.3, 60.04, -12.21, 83.65' },
  { label: 'Guatemala', value: '-92.23, 13.74, -88.23, 17.82' },
  { label: 'Guyana', value: '-61.41, 1.27, -56.54, 8.37' },
  { label: 'Haiti', value: '-74.46, 18.03, -71.62, 19.92' },
  { label: 'Honduras', value: '-89.35, 12.98, -83.15, 16.01' },
  { label: 'Hungary', value: '16.2, 45.76, 22.71, 48.62' },
  { label: 'Iceland', value: '-24.33, 63.5, -13.61, 66.53' },
  { label: 'India', value: '68.18, 7.97, 97.4, 35.49' },
  { label: 'Indonesia', value: '95.29, -10.36, 141.03, 5.48' },
  { label: 'Iran', value: '44.11, 25.08, 63.32, 39.71' },
  { label: 'Iraq', value: '38.79, 29.1, 48.57, 37.39' },
  { label: 'Ireland', value: '-9.98, 51.67, -6.03, 55.13' },
  { label: 'Israel', value: '34.27, 29.5, 35.84, 33.28' },
  { label: 'Italy', value: '6.75, 36.62, 18.48, 47.12' },
  { label: 'Ivory Coast', value: '-8.6, 4.34, -2.56, 10.52' },
  { label: 'Jamaica', value: '-78.34, 17.7, -76.2, 18.52' },
  { label: 'Japan', value: '129.41, 31.03, 145.54, 45.55' },
  { label: 'Jordan', value: '34.92, 29.2, 39.2, 33.38' },
  { label: 'Kazakhstan', value: '46.47, 40.66, 87.36, 55.39' },
  { label: 'Kenya', value: '33.89, -4.68, 41.86, 5.51' },
  { label: 'Kuwait', value: '46.57, 28.53, 48.42, 30.06' },
  { label: 'Kyrgyzstan', value: '69.46, 39.28, 80.26, 43.3' },
  { label: 'Laos', value: '100.12, 13.88, 107.56, 22.46' },
  { label: 'Latvia', value: '21.06, 55.62, 28.18, 57.97' },
  { label: 'Lebanon', value: '35.13, 33.09, 36.61, 34.64' },
  { label: 'Lesotho', value: '27.0, -30.65, 29.33, -28.65' },
  { label: 'Liberia', value: '-11.44, 4.36, -7.54, 8.54' },
  { label: 'Libya', value: '9.32, 19.58, 25.16, 33.14' },
  { label: 'Lithuania', value: '21.06, 53.91, 26.59, 56.37' },
  { label: 'Luxembourg', value: '5.67, 49.44, 6.24, 50.13' },
  { label: 'Macedonia', value: '20.46, 40.84, 22.95, 42.32' },
  { label: 'Madagascar', value: '43.25, -25.6, 50.48, -12.04' },
  { label: 'Malaysia', value: '100.09, 0.77, 119.18, 6.93' },
  { label: 'Malawi', value: '32.69, -16.8, 35.77, -9.23' },
  { label: 'Mali', value: '-12.17, 10.1, 4.27, 24.97' },
  { label: 'Mauritania', value: '-17.06, 14.62, -4.92, 27.4' },
  { label: 'Mexico', value: '-117.13, 14.54, -86.81, 32.72' },
  { label: 'Moldova', value: '26.62, 45.49, 30.02, 48.47' },
  { label: 'Mongolia', value: '87.75, 41.6, 119.77, 52.05' },
  { label: 'Montenegro', value: '18.45, 41.88, 20.34, 43.52' },
  { label: 'Morocco', value: '-17.02, 21.42, -1.12, 35.76' },
  { label: 'Mozambique', value: '30.18, -26.74, 40.78, -10.32' },
  { label: 'Myanmar', value: '92.3, 9.93, 101.18, 28.34' },
  { label: 'Namibia', value: '11.73, -29.05, 25.08, -16.94' },
  { label: 'New Caledonia', value: '164.03, -22.4, 167.12, -20.11' },
  { label: 'Nepal', value: '80.09, 26.4, 88.17, 30.42' },
  { label: 'Netherlands', value: '3.31, 50.8, 7.09, 53.51' },
  { label: 'New Zealand', value: '166.51, -46.64, 178.52, -34.45' },
  { label: 'Nicaragua', value: '-87.67, 10.73, -83.15, 15.02' },
  { label: 'Niger', value: '0.3, 11.66, 15.9, 23.47' },
  { label: 'Nigeria', value: '2.69, 4.24, 14.58, 13.87' },
  { label: 'North Korea', value: '124.27, 37.67, 130.78, 42.99' },
  { label: 'Norway', value: '4.99, 58.08, 31.29, 70.92' },
  { label: 'Oman', value: '52.0, 16.65, 59.81, 26.4' },
  { label: 'Pakistan', value: '60.87, 23.69, 77.84, 37.13' },
  { label: 'Panama', value: '-82.97, 7.22, -77.24, 9.61' },
  { label: 'Papua New Guinea', value: '141.0, -10.65, 156.02, -2.5' },
  { label: 'Paraguay', value: '-62.69, -27.55, -54.29, -19.34' },
  { label: 'Peru', value: '-81.41, -18.35, -68.67, -0.06' },
  { label: 'Philippines', value: '117.17, 5.58, 126.54, 18.51' },
  { label: 'Poland', value: '14.07, 49.03, 24.03, 54.85' },
  { label: 'Portugal', value: '-9.53, 36.84, -6.39, 42.28' },
  { label: 'Puerto Rico', value: '-67.24, 17.95, -65.59, 18.52' },
  { label: 'Qatar', value: '50.74, 24.56, 51.61, 26.11' },
  { label: 'Romania', value: '20.22, 43.69, 29.63, 48.22' },
  { label: 'Russia', value: '-180.0, 41.15, 180.0, 81.25' },
  { label: 'Rwanda', value: '29.02, -2.92, 30.82, -1.13' },
  { label: 'Saudi Arabia', value: '34.63, 16.35, 55.67, 32.16' },
  { label: 'Senegal', value: '-17.63, 12.33, -11.47, 16.6' },
  { label: 'Serbia', value: '18.83, 42.25, 22.99, 46.17' },
  { label: 'Sierra Leone', value: '-13.25, 6.79, -10.23, 10.05' },
  { label: 'Slovakia', value: '16.88, 47.76, 22.56, 49.57' },
  { label: 'Slovenia', value: '13.7, 45.45, 16.56, 46.85' },
  { label: 'Solomon Islands', value: '156.49, -10.83, 162.4, -6.6' },
  { label: 'Somalia', value: '40.98, -1.68, 51.13, 12.02' },
  { label: 'South Africa', value: '16.34, -34.82, 32.83, -22.09' },
  { label: 'South Korea', value: '126.12, 34.39, 129.47, 38.61' },
  { label: 'South Sudan', value: '23.89, 3.51, 35.3, 12.25' },
  { label: 'Spain', value: '-9.39, 35.95, 3.04, 43.75' },
  { label: 'Sri Lanka', value: '79.7, 5.97, 81.79, 9.82' },
  { label: 'Sudan', value: '21.94, 8.62, 38.41, 22.0' },
  { label: 'Suriname', value: '-58.04, 1.82, -53.96, 6.03' },
  { label: 'Swaziland', value: '30.68, -27.29, 32.07, -25.66' },
  { label: 'Sweden', value: '11.03, 55.36, 23.9, 69.11' },
  { label: 'Switzerland', value: '6.02, 45.78, 10.44, 47.83' },
  { label: 'Syria', value: '35.7, 32.31, 42.35, 37.23' },
  { label: 'Taiwan', value: '120.11, 21.97, 121.95, 25.3' },
  { label: 'Tanzania', value: '29.34, -11.72, 40.32, -0.95' },
  { label: 'Tajikistan', value: '67.44, 36.74, 74.98, 40.96' },
  { label: 'Thailand', value: '97.38, 5.69, 105.59, 20.42' },
  { label: 'Togo', value: '-0.05, 5.93, 1.87, 11.02' },
  { label: 'Trinidad and Tobago', value: '-61.95, 10.0, -60.9, 10.89' },
  { label: 'Tunisia', value: '7.52, 30.31, 11.49, 37.35' },
  { label: 'Turkey', value: '26.04, 35.82, 44.79, 42.14' },
  { label: 'Turkmenistan', value: '52.5, 35.27, 66.55, 42.75' },
  { label: 'Uganda', value: '29.58, -1.44, 35.04, 4.25' },
  { label: 'Ukraine', value: '22.09, 44.36, 40.08, 52.34' },
  { label: 'United Arab Emirates', value: '51.58, 22.5, 56.4, 26.06' },
  { label: 'United Kingdom', value: '-7.57, 49.96, 1.68, 58.64' },
  { label: 'United States', value: '-125.0, 25.0, -66.96, 49.5' },
  { label: 'Uruguay', value: '-58.43, -34.95, -53.21, -30.11' },
  { label: 'Uzbekistan', value: '55.93, 37.14, 73.06, 45.59' },
  { label: 'Vanuatu', value: '166.63, -16.6, 167.84, -14.63' },
  { label: 'Venezuela', value: '-73.3, 0.72, -59.76, 12.16' },
  { label: 'Vietnam', value: '102.17, 8.6, 109.34, 23.35' },
  { label: 'West Bank', value: '34.93, 31.35, 35.55, 32.53' },
  { label: 'Yemen', value: '42.6, 12.59, 53.11, 19.0' },
  { label: 'Zambia', value: '21.89, -17.96, 33.49, -8.24' },
  { label: 'Zimbabwe', value: '25.26, -22.27, 32.85, -15.51' },
];

export const darkStyle = new google.maps.StyledMapType([
  {
    stylers: [
      {
        saturation: -100,
      },
      {
        lightness: -65,
      },
    ],
  },
  {
    elementType: 'labels',
    stylers: [
      {
        saturation: -100,
      },
    ],
  },
  {
    featureType: 'administrative',
    elementType: 'labels.text.fill',
    stylers: [
      {
        color: '#9e9e9e',
      },
    ],
  },
  {
    featureType: 'administrative',
    elementType: 'labels.text.stroke',
    stylers: [
      {
        visibility: 'off',
      },
    ],
  },
  {
    featureType: 'administrative.country',
    elementType: 'geometry.stroke',
    stylers: [
      {
        color: '#b0b0b0',
      },
    ],
  },
  {
    featureType: 'administrative.country',
    elementType: 'labels.text.fill',
    stylers: [
      {
        color: '#ffffff',
      },
    ],
  },
  {
    featureType: 'administrative.land_parcel',
    stylers: [
      {
        visibility: 'off',
      },
    ],
  },
  {
    featureType: 'administrative.land_parcel',
    elementType: 'labels.text.fill',
    stylers: [
      {
        color: '#7d7d7d',
      },
    ],
  },
  {
    featureType: 'administrative.land_parcel',
    elementType: 'labels.text.stroke',
    stylers: [
      {
        visibility: 'off',
      },
    ],
  },
  {
    featureType: 'administrative.locality',
    elementType: 'labels.text.fill',
    stylers: [
      {
        color: '#9e9e9e',
      },
    ],
  },
  {
    featureType: 'administrative.neighborhood',
    stylers: [
      {
        visibility: 'off',
      },
    ],
  },
  {
    featureType: 'administrative.neighborhood',
    elementType: 'labels.text.fill',
    stylers: [
      {
        color: '#787878',
      },
    ],
  },
  {
    featureType: 'administrative.province',
    elementType: 'geometry.stroke',
    stylers: [
      {
        color: '#999999',
      },
    ],
  },
  {
    featureType: 'administrative.province',
    elementType: 'labels.text.fill',
    stylers: [
      {
        color: '#ebebeb',
      },
    ],
  },
  {
    featureType: 'administrative.province',
    elementType: 'labels.text.stroke',
    stylers: [
      {
        visibility: 'off',
      },
    ],
  },
  {
    featureType: 'landscape.natural',
    elementType: 'labels',
    stylers: [
      {
        visibility: 'off',
      },
    ],
  },
  {
    featureType: 'landscape.natural.landcover',
    stylers: [
      {
        visibility: 'off',
      },
    ],
  },
  {
    featureType: 'landscape.natural.terrain',
    stylers: [
      {
        lightness: -50,
      },
    ],
  },
  {
    featureType: 'poi',
    stylers: [
      {
        visibility: 'off',
      },
    ],
  },
  {
    featureType: 'poi',
    elementType: 'labels.icon',
    stylers: [
      {
        visibility: 'off',
      },
    ],
  },
  {
    featureType: 'poi',
    elementType: 'labels.text',
    stylers: [
      {
        visibility: 'off',
      },
    ],
  },
  {
    featureType: 'road',
    elementType: 'geometry.fill',
    stylers: [
      {
        color: '#616161',
      },
    ],
  },
  {
    featureType: 'road',
    elementType: 'geometry.stroke',
    stylers: [
      {
        visibility: 'off',
      },
    ],
  },
  {
    featureType: 'road',
    elementType: 'labels.icon',
    stylers: [
      {
        visibility: 'off',
      },
    ],
  },
  {
    featureType: 'road',
    elementType: 'labels.text.fill',
    stylers: [
      {
        color: '#9b9b9b',
      },
    ],
  },
  {
    featureType: 'road.arterial',
    elementType: 'geometry.stroke',
    stylers: [
      {
        visibility: 'off',
      },
    ],
  },
  {
    featureType: 'road.highway',
    elementType: 'geometry.stroke',
    stylers: [
      {
        visibility: 'off',
      },
    ],
  },
  {
    featureType: 'road.local',
    elementType: 'geometry.stroke',
    stylers: [
      {
        visibility: 'off',
      },
    ],
  },
  {
    featureType: 'transit',
    stylers: [
      {
        visibility: 'off',
      },
    ],
  },
  {
    featureType: 'water',
    stylers: [
      {
        color: '#1c1c1c',
      },
    ],
  },
  {
    featureType: 'water',
    elementType: 'labels.text',
    stylers: [
      {
        visibility: 'off',
      },
    ],
  },
]);

export const lightStyle = new google.maps.StyledMapType([
  {
    elementType: 'geometry',
    stylers: [
      {
        saturation: -100,
      },
    ],
  },
  {
    elementType: 'labels',
    stylers: [
      {
        saturation: -100,
      },
    ],
  },
  {
    elementType: 'labels.text.stroke',
    stylers: [
      {
        visibility: 'off',
      },
    ],
  },
  {
    featureType: 'administrative',
    elementType: 'labels.text.stroke',
    stylers: [
      {
        visibility: 'off',
      },
    ],
  },
  {
    featureType: 'administrative.country',
    elementType: 'labels',
    stylers: [
      {
        color: '#000000',
      },
      {
        visibility: 'on',
      },
    ],
  },
  {
    featureType: 'administrative.country',
    elementType: 'labels.text.stroke',
    stylers: [
      {
        color: '#ffffff',
      },
      {
        visibility: 'off',
      },
    ],
  },
  {
    featureType: 'administrative.land_parcel',
    stylers: [
      {
        visibility: 'off',
      },
    ],
  },
  {
    featureType: 'administrative.land_parcel',
    elementType: 'geometry.stroke',
    stylers: [
      {
        color: '#4285f4',
      },
    ],
  },
  {
    featureType: 'administrative.land_parcel',
    elementType: 'labels',
    stylers: [
      {
        visibility: 'off',
      },
    ],
  },
  {
    featureType: 'administrative.land_parcel',
    elementType: 'labels.text.fill',
    stylers: [
      {
        color: '#bdbdbd',
      },
    ],
  },
  {
    featureType: 'administrative.locality',
    elementType: 'labels.text.fill',
    stylers: [
      {
        color: '#828282',
      },
    ],
  },
  {
    featureType: 'administrative.neighborhood',
    stylers: [
      {
        visibility: 'off',
      },
    ],
  },
  {
    featureType: 'administrative.neighborhood',
    elementType: 'labels.text.fill',
    stylers: [
      {
        color: '#a3a3a3',
      },
    ],
  },
  {
    featureType: 'administrative.province',
    elementType: 'labels.text.fill',
    stylers: [
      {
        color: '#3b3b3b',
      },
    ],
  },
  {
    featureType: 'landscape.natural',
    elementType: 'labels',
    stylers: [
      {
        visibility: 'off',
      },
    ],
  },
  {
    featureType: 'landscape.natural.landcover',
    stylers: [
      {
        visibility: 'off',
      },
    ],
  },
  {
    featureType: 'poi',
    stylers: [
      {
        visibility: 'off',
      },
    ],
  },
  {
    featureType: 'poi',
    elementType: 'labels.icon',
    stylers: [
      {
        visibility: 'off',
      },
    ],
  },
  {
    featureType: 'road',
    elementType: 'geometry.fill',
    stylers: [
      {
        color: '#d9d9d9',
      },
    ],
  },
  {
    featureType: 'road',
    elementType: 'geometry.stroke',
    stylers: [
      {
        visibility: 'off',
      },
    ],
  },
  {
    featureType: 'road',
    elementType: 'labels.icon',
    stylers: [
      {
        visibility: 'off',
      },
    ],
  },
  {
    featureType: 'road.arterial',
    elementType: 'geometry.stroke',
    stylers: [
      {
        visibility: 'off',
      },
    ],
  },
  {
    featureType: 'road.local',
    elementType: 'geometry.stroke',
    stylers: [
      {
        visibility: 'off',
      },
    ],
  },
  {
    featureType: 'transit',
    stylers: [
      {
        visibility: 'off',
      },
    ],
  },
  {
    featureType: 'water',
    elementType: 'geometry',
    stylers: [
      {
        color: '#ffffff',
      },
      {
        visibility: 'on',
      },
    ],
  },
  {
    featureType: 'water',
    elementType: 'labels.text',
    stylers: [
      {
        visibility: 'off',
      },
    ],
  },
]);

export const selectCustomStyles17rem = {
  container: (provided) => ({
    ...provided,
    background: 'var(--background-color)',
    color: 'var(--foreground-color)',
    width: '100%',
  }),
  control: (provided) => ({
    ...provided,
    background: 'var(--background-color)',
    color: 'var(--foreground-color)',
    borderColor: 'var(--foreground-color)',
    // Removes weird border around container
    boxShadow: null,
    '&:hover': {
      color: 'blue',
      // Overwrittes the different states of border
      borderColor: 'var(--foreground-color)',
    },
    height: '1.1rem',
    padding: '0.25rem',
    margin: '0.25rem',
    maxHeight: '1.25rem',
  }),
  input: (provided) => ({
    ...provided,
    color: 'var(--foreground-color)',
    padding: '0.25rem',
    height: '1rem',
    margin: '0rem',
  }),
  menu: (provided) => ({
    ...provided,
    background: 'var(--background-color)',
    borderRadius: 0,
    // kill the gap
    marginTop: '0rem',
    height: '1.25rem',
    padding: '0rem',
    margin: '0rem',
  }),
  menuList: (provided) => ({
    ...provided,
    background: 'var(--background-color)',
    color: 'var(--foreground-color)',
    // kill the white space on first and last option
    padding: '0.25rem',
    margin: '0.25rem',
    marginTop: '-0.1rem', //-3,
    lineHeight: '0.375rem',
    borderBottom: '0.0625rem solid var(--foreground-color)',
    borderTop: '0.0625rem solid var(--foreground-color)',
    borderLeft: '0.0625rem solid var(--foreground-color)',
    maxHeight: '15rem',
    overflowY: 'visible',
    overflowX: 'hidden',
  }),
  option: (provided) => ({
    ...provided,
    color: 'var(--foreground-color)',
    background: 'var(--background-color)',
    height: '1.125rem',
    marginTop: '0rem',
    marginBottom: '0rem',
    padding: '0.25rem',
    '&:hover': {
      background: 'gray',
      opacity: 1.0,
      // Overwrittes the different states of border
      borderColor: 'var(--foreground-color)',
    },
  }),
  placeholder: (provided) => ({
    ...provided,
    color: 'var(--foreground-color)',
    height: '1rem',
  }),
  singleValue: (provided) => ({
    ...provided,
    color: 'var(--foreground-color)',
    height: '1rem',
  }),
};

export const APIBaseUrl = process.env.APIBaseUrl
? process.env.APIBaseUrl
: 'https://skytruth-alerts2.appspot.com/api';

export const tileServerBaseUrl = process.env.tileServerBaseUrl
  ? process.env.tileServerBaseUrl
  : 'https://alerts2tileserver-hqwi6i5era-ue.a.run.app/api';

  export const HEAP_ANALYTICS_APP_IDS = {
    PRODUCTION: '1227912707',
    DEVELOP: '4147619035'
  };