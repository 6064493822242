import React from 'react';
import Select from 'react-select';
import * as region from "../actions/region";
import { states } from '../actions/alerts';
import { countries } from "../utils/constants";
import { selectCustomStyles17rem } from "../utils/constants";
import { loadAoiPolygon } from '../utils/geometry';
import { updateUserProfile } from '../actions/settings';



let aoiPolygon = null;


class PredefinedAois extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            modalIsOpen: true,
            selectedState: null,
            selectedCountry: null,
            selectedCounty: null,
            countiesMenuOpen: false,
            watershedMenuOpen: false,
            counties: [],
            watersheds: [],
            eezs: [],
            basins: [],
            TOSGPs: [],
            counties: [],
            initBboxSaved: false,
            saveClicked: false,
            message: null,
            showContinue: false,
        };
    }

    componentDidMount() {
        if (this.props.userProfile && this.props.userProfile.init_bbox === null) {
            this.updateToDefault();
        }
    }

    initDefaultBbox = () => {
        // "-125.0, 25.0, -66.96, 49.5"
        let bounds = new google.maps.LatLngBounds();
        let corner1 = new google.maps.LatLng(49.5, -125.0);
        bounds.extend(corner1)
        let corner2 = new google.maps.LatLng(25.0, -66.96);
        bounds.extend(corner2)
        this.props.map.fitBounds(bounds, 0);
    }

    updateToDefault = () => {
        this.initDefaultBbox();
        this.updateInitBbox();
    }

    saveClicked = () => {
        this.setState({ saveClicked: true }, () => {
            this.updateInitBbox();
        })
    }

    continueToMap = () => {
        this.props.setTab(0);
    }

    handleCountryChange = (selectedOption) => {
        this.resetAoiPolygon();
        let bbox = selectedOption.value.split(",");
        this.setState({
            selectedView: "Country: " + selectedOption.label,
            selectedCounty: null, selectedState: null, selectedCountry: selectedOption
        });
        let bounds = new google.maps.LatLngBounds();
        let corner1 = new google.maps.LatLng(parseFloat(bbox[3]), parseFloat(bbox[0]));
        bounds.extend(corner1)
        let corner2 = new google.maps.LatLng(parseFloat(bbox[1]), parseFloat(bbox[2]));
        bounds.extend(corner2)
        this.props.map.fitBounds(bounds, 0);
    }

    setCounties = (counties) => {
        this.setState({ counties: counties })
    }

    updateInitBbox = () => {
        const mapbounds = this.props.map.getBounds();
        let north = mapbounds.getNorthEast().lat()
        let south = mapbounds.getSouthWest().lat()
        let west = mapbounds.getSouthWest().lng()
        let east = mapbounds.getNorthEast().lng()
        let bBox = west + "," + south + "," + east + "," + north;
        updateUserProfile(this.props.token, this.props.userId, null, null, null, bBox)
            .then(res => {
                if (res == 200) {
                    if (this.state.saveClicked) {
                        let showContinue = false;
                        if (this.props.showBbox && this.props.firstLoginStep == null) showContinue = true;
                        if (showContinue)
                            this.setState({ initBboxSaved: true, showContinue, message: "Your starting map location has been saved!" }, () => {
                                this.props.setShowBbox(false);
                            })
                        else
                            if (this.props.firstLoginStep === "bbox") this.props.setNextStep("alerts");
                            else this.props.closeSelectedAlerts("Your starting map location has been saved!")
                    }
                }
            });
    }

    handleStateChange = (selectedOption) => {
        this.resetAoiPolygon();
        this.setState({ selectedOption, selectedView: selectedOption.label, selectedCountry: null });
        this.exploreThisRegion(selectedOption.value)
        this.setState({ selectedState: selectedOption, selectedCounty: null })
        region.loadRegionsByParent(this.props.token, selectedOption.value, this.setCounties);
    }

    setCounties = (counties) => {
        this.setState({ counties: counties })
    }

    exploreThisRegion = async (selectedRegion) => {
        region.loadRegionSimpleGeom(selectedRegion)
            .then(res => {
                let stuff = loadAoiPolygon(res)
                if (stuff.length > 1 && stuff[0].length > 2) stuff = stuff[0];
                let bounds = new google.maps.LatLngBounds();
                stuff.forEach(point => {
                    bounds.extend(point)
                });
                this.props.map.fitBounds(bounds, 0);
                this.showGeom(stuff);
            })
    }

    handleCountyChange = (selectedOption) => {
        this.resetAoiPolygon();
        this.setState({
            selectedCounty: selectedOption, selectedOption, selectedView: selectedOption.label,
            selectedCountry: null, selectedState: null
        });
        this.exploreThisRegion(selectedOption.value)
    }

    showGeom = (thisPolygon) => {
        let strokeColor = "black";
        aoiPolygon = new google.maps.Polygon({
            paths: thisPolygon,
            strokeColor: strokeColor, //'black', //'#FF0000',
            strokeOpacity: 0.7,
            strokeWeight: 5,
            fillOpacity: 0,
            clickable: false,
            draggable: false,
        });
        aoiPolygon.setMap(this.props.map);
    }

    resetAoiPolygon = () => {
        if (aoiPolygon) aoiPolygon.setMap(null);
        aoiPolygon = null;
    }

    render() {
        return (
            <div id="bboxUpdate">
                {!this.state.saveClicked &&
                    <div>
                        {this.props.userProfile !== null && !this.props.userProfile.init_bbox &&
                            <div id="bboxInit">
                                <div>This is your first time logging in with this User Id, so please select a starting map location. If
                                    you don’t select a starting location, Monitor will open showing the continental United States.</div>
                            </div>
                        }
                        <p>Choose a location by navigating to it on the map or selecting a country, U.S. state, or county from the dropdowns.
                        </p>
                        <p>
                        </p>
                        <div className="preSelect">
                            <Select
                                placeholder='Select a country'
                                onChange={this.handleCountryChange}
                                options={countries}
                                styles={selectCustomStyles17rem}
                                value={this.state.selectedCountry}
                                maxMenuHeight={20}
                            >
                            </Select>
                        </div>
                        <div className="preSelect">
                            <Select
                                placeholder='Select a U.S. state'
                                onChange={this.handleStateChange}
                                options={states}
                                value={this.state.selectedState}
                                styles={selectCustomStyles17rem}
                            >
                            </Select>
                        </div>
                        {(this.state.selectedState !== null || this.state.counties.length > 0) &&
                            <div className="preSelect">
                                <Select
                                    placeholder='Select a county'
                                    onChange={this.handleCountyChange}
                                    options={this.state.counties}
                                    value={this.state.selectedCounty}
                                    styles={selectCustomStyles17rem}
                                >
                                </Select>
                            </div>
                        }
                        {this.props.userProfile !== null &&
                            <p>
                                <button
                                    onClick={this.saveClicked}
                                    className="mapOptionButton"
                                    style={{ "marginTop": "0.875rem", "fontSize": "1rem" }}
                                >
                                    Set Starting Map to Current Map
                                </button>
                            </p>
                        }
                    </div>
                }
                {this.state.message !== null &&
                    <div>
                        {this.state.message}
                        {this.state.showContinue && this.props.firstLoginStep == null &&
                            <p>
                                <button
                                    onClick={this.continueToMap}
                                    className="mapOptionButton"
                                    style={{ "marginTop": "0.875rem", "fontSize": "1rem" }}
                                >
                                    Continue to map
                                </button>
                            </p>
                        }
                    </div>
                }
            </div>
        );
    }
}


export default PredefinedAois;
