import React from 'react';
import * as auth from "../actions/auth";
import queryString from 'query-string';


class PwChange extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modalIsOpen: true,
      returnMsg: '',
      password: '',
      password2: '',
      showForm: true,
      username: '',
      a: '',
    };
  }

  openModal() {
    this.setState({ modalIsOpen: true });
  }

  afterOpenModal() {
  }

  closeModal = () => {
    this.setState({modalIsOpen: false});
    this.props.history.push('/');
  }

  onSubmit = e => {
    e.preventDefault();
    if (this.state.password !== this.state.password2)
      this.setState({ returnMsg: "Passwords must match." })
    else {
      auth.resetPw(this.state.username, this.state.password, this.state.a)
        .then(res => {
          this.setState({ returnMsg: res.data });
          if (res.status === "OK")
            this.setState({ showForm: false });
        });
    }
  }

  componentDidMount() {
    let params = queryString.parse(this.props.location.search);
    this.setState({username: params.u})
    this.setState({a:params.a})
  }

  render() {
    return (
      <div id="unsubscribe">
        <div>
          <h3>Password Reset</h3>
          <form onSubmit={this.onSubmit} className={this.state.showForm !== true ? 'hidden' : ''}>
            <div>
              <input 
                autoFocus
                type="text" id="a"
                value={this.state.a}
                className="hidden"
                readOnly />
              <label htmlFor="username">Username:&nbsp;</label>
              <input
                type="text" id="username"
                value={this.state.username}
                className="noLines"
                readOnly />
              <br />
              <br />
              <label htmlFor="password">New Password:&nbsp;</label>
              <input
                type="password" id="password" tabIndex="2"
                autoComplete="current-password"
                onChange={e => this.setState({ password: e.target.value })} />
              <br />
              <br />
              <label htmlFor="password2">Retype New Password:&nbsp;</label>
              <input
                type="password" id="password2" tabIndex="3"
                autoComplete="current-password"
                onChange={e => this.setState({ password2: e.target.value })} />
              <br />
              <br />
              <button type="submit" className="drawicon">Reset Password</button>
              &nbsp;
                <button type="reset" className="drawicon" onClick={this.closeModal}>Cancel</button>
              <br />
              <br />
            </div>
          </form>
          <div className="returnMsg">{this.state.returnMsg}</div>
          {this.state.showForm === false &&
            <div>
              <br/>
              <a  onClick={this.closeModal} >Continue</a>
            </div>
          }
        </div>
      </div>
    )
  }
}


export default PwChange;
