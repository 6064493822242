import { postLog } from '../utils/log';
import { getLayers } from "../actions/layers";
import { clickSubscriptionId } from '../actions/aois';
import * as regionAction from "../actions/region";


export const getIssue = (issueId) => {
    // console.log('getIssue', issueId)
    if (!issueId) return null;
    let headers = {
        "Content-Type": "application/json",
    };
    // return fetch(`http://localhost:8000/api/getissue/?id=${issueId}`, { headers, })
    return fetch(`https://skytruth-alerts2.appspot.com/api/getissue/?id=${issueId}`, { headers, })
        .then(res => {
            if (res.status < 500) {
                return res.json().then(data => {
                    return { status: res.status, data };
                })
            } else {
                console.log("Server Error!");
                throw res;
            }
        })
        .then(res => {
            if (res.status === 200) {
                return res
            } else if (res.status >= 400 && res.status < 500) {
                dispatch({ type: "AUTHENTICATION_ERROR", data: res.data });
                throw res.data;
            }
        })
}


export const showIssue = (
    map,
    setMap,
    issueId,
    setIssue,
    setAlertsSearchReady,
    setZoom,
    setIssueFeeds,
    setAlertsKeyword,
    setLayers,
    setLat,
    setLng,
    setIncludeLegend,
    setCluster,
    setAoi,
    setAoiSelected,
    setLimitToAoi,
    setAlertsDateRange,
    setMapType,
    setRegion,
    setLockMap,
    setSatImage,
    setSatImageType,
    setReportId,
    setSetbacks,
    setDrawingType,
    setLayerTimeline,
    setLayerTimelineMap,
    user,
    setMessage,
    setShowSatellite,
    setMapLayersReadyToShow,
    setCompareImages) => {

    getIssue(issueId, user)
        .then(res => {
            if (res.status === 200) {
                let issue = null;
                if (res.data.length > 0) {
                    issue = res.data[0].fields; //JSON.parse(res.data[0].fields);
                    issue.pk = issueId;
                    if (checkAccess(user, issue)) {
                        setMessage(null)
                        showIssueMap(issue, user)
                    }
                    else {
                        setMessage(['You do not have access to this issue map'])
                        setMapLayersReadyToShow(true)
                        return; // { status: "No access" };
                    }
                }
                else {
                    console.log(issueId + ' not found')
                    setIssue(null)
                }
            }
        }
        )

    const checkAccess = (user, issue) => {
        //console.log('user email=', user.email, ' issue=', issue.security_option, issue.email_access)
        let ok = false;
        if (issue.security_option == 'anyone') ok = true
        else if (issue.security_option == 'only me' && issue.user == user.id) ok = true
        else if (issue.security_option == 'users I identify') {
            if (issue.email_access !== null && user.email !== null
                && issue.email_access.toUpperCase().includes(user.email.toUpperCase())) ok = true
            else if (user.id !== null && issue.user == user.id) ok = true
            else if (user.email !== null) {
                // check for domain match
                let startDomainPos = user.email.indexOf('@');
                let userDomain = user.email.substring(startDomainPos).toUpperCase();
                let email_access = issue.email_access.toUpperCase();
                for (var i = 0; i < email_access.length && ok == false; i++) {
                    if (email_access.charAt(i) == '@') {
                        if (i == 0 || email_access.charAt(i - 1) == ' ' || email_access.charAt(i - 1) == ',') {
                            let matched = true;
                            let incr = 0;
                            for (var ii = 0; ii < userDomain.length; ii++) {
                                if (userDomain.charAt(ii) != email_access.charAt(i + incr)) {
                                    matched = false;
                                    break;
                                }
                                else {
                                    incr++;
                                }
                            }
                            if (matched) {
                                ok = true
                            }
                        }
                    }
                };
            }
        }
        return ok;
    }

    const exploreSelectedRegion = (newGeom, area) => {
        let exploreNew = {
            "aoidescr": "",
            "geom": newGeom, // [newPoly],
            "length": 10, //newaoiShape.length + 1,
            "requestid": "explorenew",
            "requeststatus": "explorenew",
            "area": area,
        };
        setAoi(exploreNew);
        setAoiSelected(true);
        setLimitToAoi(true);
    }

    const showIssueMap = (issue, user) => {
        // console.log('issueMap:', issue)
        setIssue(issue);
        postLog(user.id, 'getIssue', issue.id, null, issueId)

        if (issue.lat !== null) {
            map.setCenter({ lat: issue.lat, lng: issue.lng });
            map.setZoom(issue.zoom);
            setMap(map)
        }
        if (issue.lat) setLat(issue.lat)
        if (issue.lng) setLng(issue.lng)
        if (issue.zoom) setZoom(issue.zoom)

        if (issue.legend) setIncludeLegend(true);
        if (issue.cluster) setCluster(true);
        if (issue.alert_id) {
            setReportId(issue.alert_id);
            checkTheRest(issue);
        }
        else if (issue.sub_id > '') {
            clickSubscriptionId(issue.sub_id, null, setAoi, setAoiSelected, null, setLimitToAoi, null, null)
                .then(res => {
                    checkTheRest(issue);
                })
        }
        else if (issue.region_id > '') {
            setRegion(issue.region_id);
            regionAction.loadRegion(null, issue.region_id, null, null, exploreSelectedRegion);
            checkTheRest(issue);
        }
        else {
            checkTheRest(issue);
        }
    }

    const checkTheRest = (issue) => {
        if (issue.limit_to_aoi) setLimitToAoi(true);
        if (issue.setbacks) {
            let setbacks = eval(issue.setbacks);
            setbacks.forEach((setback, x) => {
                setbacks[x] = eval(setback)
            })
            setSetbacks(setbacks)
        }
        if (issue.map_type == "custom")
            setMapType(issue.map_style)
        else
            setMapType(issue.map_type);
        if (issue.lock_map) {
            setLockMap(true)
        }
        if (issue.annotations_json !== null && issue.annotations_json > '')
            setDrawingType("annotations");
        let issueAlerts = issue.alerts.replace('[', '').replace(']', '').replace(/"/g, '').replace(' ', '').split(',').map(Number);
        if (issueAlerts.length === 1 && issueAlerts[0] === 0) issueAlerts = [];
        setIssueFeeds(issueAlerts);
        if (issue.start_date > '' && issue.end_date > '') {
            let startDate = issue.start_date;
            let endDate = issue.end_date;
            let alertsDateRange = {
                startDate: startDate == null ? null : startDate, 
                endDate: endDate == null ? null : endDate, 
            }
            setAlertsDateRange(alertsDateRange);
        }
        if (issue.alert_keyword)
            setAlertsKeyword(issue.alert_keyword);
        if (issue.timeline_layer > '') {
            setLayerTimeline(parseInt(issue.timeline_layer));
            setLayerTimelineMap(issue.map_api);
        }

        let issueLayers = issue.layers.replace('[', '').replace(']', '').replace(/"/g, '').replace(' ', '').split(',').map(Number);
        let checkedLayers = [];
        if (issue.checked_layers !== null) {
            checkedLayers = issue.checked_layers.replace('[', '').replace(']', '').replace(/"/g, '').replace(' ', '').split(',').map(Number);
        }
        let layersJson = null;
        if (issue !== null && issue.layers_json !== null && issue.layers_json !== '') {
            layersJson = JSON.parse(issue.layers_json);
        }

        getLayers(issueLayers)
            .then(res => {
                //_asyncRequest = null;
                let layers = res.data;
                if (issue.compare_image !== null && issue.compare_images > '') {
                    setLayers(layers)
                    setCompareImages(JSON.parse(issue.compare_images))
                }
                else {
                    if (issueLayers[0] == 0) {
                        issueLayers = [];
                        /* If there aren't any layers for the issue, map layers are ready to show */
                        setMapLayersReadyToShow(true)
                        setAlertsSearchReady(true)
                    }
                    else {
                        let mapSpecificLayers = false;
                        let msl = "Map Specific Layers";
                        layers.forEach((layer, key) => {
                            let promptValues = null;
                            if (layersJson !== null)
                                layersJson.layers.forEach((lj) => {
                                    if (lj.pk === layer.pk) {
                                        promptValues = lj.promptValues;
                                        if (lj.opacity !== undefined)
                                            layer.fields.opacity = lj.opacity;
                                    }
                                })
                            let layerId = layer.pk;
                            let checked = false;
                            if (layersJson !== null && layer.fields.prompts !== null && layer.fields.prompts > '') {
                                let prompts = JSON.parse(layer.fields.prompts);
                                let promptValueX = 0;
                                prompts["prompts"].forEach((prompt, promptx) => {
                                    if (prompt.type === "years") {
                                        prompt.default = promptValues[promptValueX][1];
                                        promptValueX += 1;
                                    }
                                    else if (Array.isArray(prompt.default)) {
                                        prompt.default.forEach((prmpt, prmptx) => {
                                            prompt.default[prmptx] = promptValues[promptValueX][1];
                                            promptValueX += 1;
                                        })
                                    }
                                    else {
                                        prompt.default = promptValues[promptValueX][1];
                                        promptValueX += 1;
                                    }
                                })
                                layer.fields.prompts = JSON.stringify(prompts);
                            }
                            if (layer.fields.category === msl) mapSpecificLayers = true;
                            checkedLayers.forEach((issueLayer) => {
                                if (issueLayer == layerId) {
                                    checked = true;
                                }
                            })
                            layer.checked = checked;
                        })
                        if (mapSpecificLayers) {
                            let oc = localStorage.getItem("openCategories");
                            if (oc === null || oc === "") oc = [];
                            else oc = oc.split(",");
                            if (!oc.includes(msl)) {
                                oc[oc.length] = msl;
                                localStorage.setItem("openCategories", oc);
                            }
                        }
                        if (issueLayers.length == 0 || checkedLayers[0] == 0) setAlertsSearchReady(true);
                        // this sets the prop, which is needed if loading from GoogleMap.js
                        // move the prompt replacement to here; put it in layers
                        setLayers(layers);
                    }
                }
            })
        if (issue.sat_image !== null) {
            /* Don't do Planet imagery here. Instead do in Satellite.js */
            if (issue.sat_image_type !== null && issue.sat_image_type.indexOf('PLANET') === -1) {
                setSatImage(issue.sat_image);
                setSatImageType(issue.sat_image_type);
            }
        }
        /* Don't load comparison here. Instead, load from issue in Satellite.js */
        if ((issue.sat_image !== null && issue.sat_image !== 'None') || issue.compare1_image_type != null)
            setShowSatellite(true);
    }
}


export const postIssueMap = (token, aoi, zoom, limitToAoi, user_id, annotations, issueId, descr,
    emailAccess, mapType, satImage, satImageType,
    cluster, alerts, alertsDateRange, alertsKeyword, layers, includeLegend, subscribable,
    reportId, setbacks,
    lockMap, issueContent, compareImages, lat, lng, bounds, defaultOpenContent,
    whoAccess) => {
    let selectedLayers = { "layers": [] };
    let layerCount = 0;
    layers.forEach((layer) => {
        if (layer.checked) {
            layerCount += 1;
            selectedLayers['layers'].push({ "pk": layer.pk, "promptValues": layer.promptValues, "opacity": layer.opacity, "name": layer.fields.name });
        }
    })
    if (layerCount === 0) selectedLayers = null;
    try {
        if (compareImages.indexOf("compareType\":null") > 0)
            compareImages = '';
    }
    catch { }
    let headers = {
        "Content-Type": "application/json",
        'Accept': 'application/json'
    };
    if (token) {
        headers["Authorization"] = `Token ${token}`;
    }

    let myMapLabels = []
    if (annotations.mapLabels != null)
        annotations.mapLabels.forEach((label, index) => {
            if (label !== null && label.text > '') {
                let text = label.text;
                let backgroundColor = label.backgroundColor;
                let fontColor = label.fontColor;
                let fontFamily = label.fontFamily;
                let fontSize = label.fontSize;
                let position = label.position;
                myMapLabels.push({
                    'label': {
                        'text': text, 'backgroundColor': backgroundColor,
                        'fontColor': fontColor, 'fontFamily': fontFamily, 'fontSize': fontSize, 'position': position
                    }
                })
            }
        })

    let myAreas = []
    if (annotations.areas != null)
        annotations.areas.forEach((label, index) => {
            let text = label.text;
            let backgroundColor = label.backgroundColor;
            let fontColor = label.fontColor;
            let fontFamily = label.fontFamily;
            let fontSize = label.fontSize;
            let position = label.position;
            let includeArea = true;
            if (label.map == null) includeArea = false;
            myAreas.push({
                'label': {
                    'text': text, 'backgroundColor': backgroundColor,
                    'fontColor': fontColor, 'fontFamily': fontFamily, 'fontSize': fontSize, 'position': position, 'includeArea': includeArea
                }
            })
        })

    let myMarkers = [];
    annotations.markers.forEach((marker, index) => {
        let lat = marker.getPosition().lat();
        let lng = marker.getPosition().lng();
        let url = marker.getIcon(); //.url;
        let label = marker.getLabel();
        let clickon = marker.clickon;
        myMarkers.push({
            'marker': {
                'lat': lat,
                'lng': lng,
                'url': url,
                'label': label,
                'clickon': clickon,
            }
        })
    })
    let myshapes = [];
    annotations.shapes.forEach((shape, index) => {
        const coordinates = [];
        if (shape['type'] === 'polygon') {
            shape.getPath().forEach((loc) => coordinates.push({ lat: loc.lat(), lng: loc.lng() }));
            myshapes.push({
                'shape': {
                    'type': shape['type'], 'coordinates': coordinates,
                    'strokeColor': shape['strokeColor'],
                    'fillColor': shape['fillColor'],
                    'fillOpacity': shape['fillOpacity'],
                }
            });
        }
        if (shape['type'] === 'polyline') {
            shape.getPath().forEach((loc) => coordinates.push({ lat: loc.lat(), lng: loc.lng() }));
            let includeArrow = true;
            if (shape.icons && shape.icons[0].icon === null)
                includeArrow = false;
            myshapes.push({
                'shape': {
                    'type': shape['type'], 'coordinates': coordinates,
                    'strokeColor': shape['strokeColor'], 'includeArrow': includeArrow
                }
            });
        }
        if (shape['type'] === 'rectangle') {
            let ne = shape.getBounds().getNorthEast();
            let sw = shape.getBounds().getSouthWest();
            coordinates.push({ lat: ne.lat(), lng: ne.lng() });
            coordinates.push({ lat: ne.lat(), lng: sw.lng() });
            coordinates.push({ lat: sw.lat(), lng: sw.lng() });
            coordinates.push({ lat: sw.lat(), lng: ne.lng() });
            myshapes.push({
                'shape': {
                    'type': shape['type'], 'coordinates': coordinates,
                    'strokeColor': shape['strokeColor'],
                    'fillColor': shape['fillColor'],
                    'fillOpacity': shape['fillOpacity']
                }
            });
        }
        if (shape['type'] === 'circle') {
            let center = shape.getCenter();
            let radius = shape.getRadius();
            myshapes.push({
                'shape': {
                    'type': shape['type'], 'latLng': center,
                    'radius': radius,
                    'strokeColor': shape['strokeColor'],
                    'fillColor': shape['fillColor'],
                    'fillOpacity': shape['fillOpacity']
                }
            })
        }
    });

    let shapes = myshapes;
    let mapLabels = myMapLabels;
    let areas = myAreas;
    let markers = myMarkers;

    let aoi_id = '';
    if (aoi !== null && aoi.id !== undefined && aoi.id !== null) {
        aoi_id = aoi.id;
    }
    let region_id = null;
    if (aoi !== null && aoi.id !== undefined && aoi.region_id !== undefined && aoi.region_id !== null) {
        region_id = aoi.region_id;
    }
    if (alertsKeyword == null) alertsKeyword = '';
    if (satImageType !== null && satImageType.indexOf('PLANET-MONTHLY') > -1)
        satImage = satImage.value;
    let layerIds = [];
    if (layers !== null)
        layers.forEach((layer) => {
            if (layer.checked)
                layerIds.push(layer.pk)
        })
    layers = layerIds;
    let start_date = null;
    let end_date = null;
    const leftSidebar = false;
    let securityOption = whoAccess;
    let discoverable = false;
    if (securityOption === "anyonePublic") {
        securityOption = "anyone";
        discoverable = true;
    }
    let body;
    body = JSON.stringify({
        aoi_id, region_id, issueId, descr, securityOption, emailAccess,
        bounds, lat, lng, zoom, mapType, satImage, satImageType, limitToAoi, user_id, cluster,
        alerts, alertsDateRange, alertsKeyword, layers, shapes, mapLabels, areas,
        markers, includeLegend, start_date, end_date, discoverable, subscribable,
        leftSidebar, reportId, setbacks,
        lockMap, issueContent, defaultOpenContent, compareImages, selectedLayers
    })
    // return fetch("http://localhost:8000/api/post_issuemap/", { headers, body, method: "POST" })
    return fetch("https://skytruth-alerts2.appspot.com/api/post_issuemap/", { headers, body, method: "POST" })
        .then(res => {
            if (res.status < 500) {
                return res.json().then(data => {
                    return { status: res.status, data };
                })
            } else {
                return { status: res.status }
            }
        })
        .then(res => {
            if (res.status === 200) {
                return res;
            } else if (res.status === 403 || res.status === 401) {
                dispatch({ type: "AUTHENTICATION_ERROR", data: res.data });
                throw res.data;
            } else {
                return { status: res.status }
            }
        })
}

export const getIssuesByUser = (id) => {
    if (!id) return null;
    let headers = {
        "Content-Type": "application/json",
    };
    //return fetch(`http://localhost:8000/api/${id}/getissues/`, { headers, })
    return fetch(`https://skytruth-alerts2.appspot.com/api/${id}/getissues/`, { headers, })
        .then(res => {
            if (res.status < 500) {
                return res.json().then(data => {
                    return { status: res.status, data };
                })
            } else {
                console.log("Server Error!");
                throw res;
            }
        })
        .then(res => {
            if (res.status === 200) {
                return res
            } else if (res.status >= 400 && res.status < 500) {
                dispatch({ type: "AUTHENTICATION_ERROR", data: res.data });
                throw res.data;
            }
        })
}

export const getPublicIssues = (status) => {
    let headers = {
        "Content-Type": "application/json",
    };
    // return fetch(`http://localhost:8000/api/getpublicissues/?status=${status}`, { headers, })
    return fetch(`https://skytruth-alerts2.appspot.com/api/getpublicissues/?status=${status}`, { headers, })
        .then(res => {
            if (res.status < 500) {
                return res.json().then(data => {
                    return { status: res.status, data };
                })
            } else {
                console.log("Server Error!");
                throw res;
            }
        })
        .then(res => {
            if (res.status === 200) {
                return res
            } else if (res.status >= 400 && res.status < 500) {
                dispatch({ type: "AUTHENTICATION_ERROR", data: res.data });
                throw res.data;
            }
        })
}

export const inactivateIssue = (issue) => {
    if (!issue) return null;
    let headers = {
        "Content-Type": "application/json",
    };
    //return fetch(`http://localhost:8000/api/${issue}/inactivateissue/`, { headers, })
    return fetch(`https://skytruth-alerts2.appspot.com/api/${issue}/inactivateissue/`, { headers, })
        .then(res => {
            if (res.status < 500) {
                return res
            } else {
                console.log("Server Error!");
                throw res;
            }
        })
        .then(res => {
            if (res.status === 200) {
                return res
            } else if (res.status >= 400 && res.status < 500) {
                dispatch({ type: "AUTHENTICATION_ERROR", data: res.data });
                throw res.data;
            }
        })
}

export const getUserIssueSub = (issueId, email) => {
    let headers = {
        "Content-Type": "application/json",
    };
    //return fetch(`http://localhost:8000/api/getuserissuesubsub/?id=${issueId}&email=${email}`, { headers, })
    return fetch(`https://skytruth-alerts2.appspot.com/api/getuserissuesubsub/?id=${issueId}&email=${email}`, { headers, })
        .then(res => {
            if (res.status < 500) {
                return res.json().then(data => {
                    return { status: res.status, data };
                })
            } else {
                console.log("Server Error!");
                throw res;
            }
        })
        .then(res => {
            if (res.status === 200) {
                return res
            } else if (res.status >= 400 && res.status < 500) {
                dispatch({ type: "AUTHENTICATION_ERROR", data: res.data });
                throw res.data;
            }
        })
}

export const updUserIssueSub = (issueId, email, value) => {
    let headers = {
        "Content-Type": "application/json",
        'Accept': 'application/json'
    };
    let body;
    body = JSON.stringify({
        issueId, email, value
    })
    //return fetch("http://localhost:8000/api/upduserissuesub/", { headers, body, method: "POST" })
    return fetch("https://skytruth-alerts2.appspot.com/api/upduserissuesub/", { headers, body, method: "POST" })
        .then(res => {
            if (res.status < 500) {
                return res.json().then(data => {
                    return { status: res.status, data };
                })
            } else {
                return { status: res.status }
            }
        })
        .then(res => {
            if (res.status === 200) {
                return res.data.status;
            } else if (res.status === 403 || res.status === 401) {
                dispatch({ type: "AUTHENTICATION_ERROR", data: res.data });
                throw res.data.status;
            } else {
                return res.data.status
            }
        })
}
