import { showInitTutorial } from "../actions/map";

const mapReducerDefaultState = {
    // if you change lat, lng or zoom, also need to change in AlertDetail.js
    lat: 39.8283,
    lng: -98.5795,
    zoom: 5,
    alertsDateRange: {
        startDate: null, //moment().startOf('month'),
        endDate: null, //moment().endOf('month'),
    },
    showAois: false,
    requestid: null,
    aois: [],
    aoi: null,
    aoiSelected: false,
    // planetMonthlyImage: null,
    // planetMonthlyImageClicked: null,
    map: null,
    drawingType: null,
    showAnnotations: false,
    newaoiShape: null,
    annotationid: null,
    displayPanel: null,
    alertsSidebar: null,
    aoiPolygon: null,
    showLogin: false,
    saveThisAoi: false,
    limitToAoi: false,
    region: null,
    reloadAois: false,
    infoTopic: null,
    editAoi: null,
    reportId: null,
    reportDetail: null,
    showPwChange: false,
    cloudCoverage: 25,
    showRegister: false,
    showAddAoi: false,
    cluster: false,
    showAlertsInfoBox: false,
    showLabels: false,
    drawShape: null,
    userProfile: null,
    headerBanner: null,
    showBanner: false,
    showOpen: false,
    showSave: false,
    configureMap: false,
    layers: [],
    layer: null,
    satImageType: 'S2',
    satImage: 'None',
    exp: false,
    feeds: [],
    selectedFeeds: [1], //[1],
    issueFeeds: null,
    annotations: {
        mapLabels: [],
        labelMarkers: [],
        areas: [],
        areaMarkers: [],
        shapes: [],
        markers: [],
    },
    mapType: 'terrain',
    publicUser: null,
    mapviewName: '',
    alertsKeyword: '',
    alertsSearchReady: true,
    includeLegend: false,
    showTimeline: null,
    showLabs: false,
    compareImages: {
        compare1SatImageType: null,
        compare1SatImage: null,
        compare1Layers: null,
        compare1PromptValues: null,
        compare1Basemap: null,
        compare1Gamma: null,
        compare1Brightness: null,
        compare2SatImageType: null,
        compare2SatImage: null,
        compare2Layers: null,
        compare2PromptValues: null,
        compare2Basemap: null,
        compare2Gamma: null,
        compare2Brightness: null,
        openCompare: false,
        map: null,
        compareType: null,
    },
    issue: null,
    lockMap: false,
    setbacks: null,
    layerTimeline: null,
    layerTimelineMap: null,
    message: null,
    showSatellite: false,
    showPublicIssues: false,
    uga: null,
    showNav: false,
    message: null,
    returnPosition: null,
    reportFrom: null,
    helpKey: null,
    mapLayersReadyToShow: false,
    layerChanges: {
        layer: null,
        opacity: .5,
        switchColor: null,
    },
    imageOpacity: 1.0,
    dndFiles: [],
    showElevation: false,
    orgAlerts: [],
    agendaJson: null,
    combinedImgs: null,
    showCompare: false,
    planetDailyImage: null,
    saveAnnotations: null,
    mapPosition: {
        lat: null,
        lng: null,
        zoom: null,
    },
    bBox: 90,
    layersY: 0,
    gamma: 0.8,
    mapInset: false,
    geometry: null,
    brightness: 100,
    showBbox: false,
    showSettings: false,
    screenshot: false,
    compareUpdateSide: "right",
    basemap: { type: "id", id: "light" },
    theme: "light",
    showNotLoggedInOptions: false,
    showInitTutorial: false,
    saveMap: false,
    issueLayers: null,
    mapTitle: '',
};

export default (state = mapReducerDefaultState, action) => {
    // console.log(action);
    switch (action.type) {
        case 'SET_LAT':
            return {
                ...state,
                lat: action.lat
            };
        case 'SET_LNG':
            return {
                ...state,
                lng: action.lng
            };
        case 'SET_ZOOM':
            return {
                ...state,
                zoom: action.zoom
            };
        case 'SET_ALERTS_DATE_RANGE':
            return {
                ...state,
                alertsDateRange: action.alertsDateRange
            };
        case 'SET_ALERTS_END':
            return {
                ...state,
                setAlertsEnd: action.setAlertsEnd
            };
        case 'SHOW_AOIS':
            return {
                ...state,
                showAois: action.showAois
            };
        case 'SET_REQUESTID':
            return {
                ...state,
                requestid: action.requestid
            }
        case 'SET_AOI':
            return {
                ...state,
                aoi: action.aoi
            }
        case 'SET_AOIS':
            return {
                ...state,
                aois: action.aois
            }
        case 'SET_AOI_SELECTED':
            return {
                ...state,
                aoiSelected: action.aoiSelected
            }
        case 'SET_PLANET_DAILY_IMAGE':
            return {
                ...state,
                planetDailyImage: action.planetDailyImage
            }
        case 'SET_MAP':
            return {
                ...state,
                map: action.map
            }
        case 'SET_DRAWING_TYPE':
            return {
                ...state,
                drawingType: action.drawingType
            }
        case 'SET_SHOW_ANNOTATIONS':
            return {
                ...state,
                showAnnotations: action.showAnnotations
            }
        case 'SET_NEWAOI_SHAPE':
            return {
                ...state,
                newaoiShape: action.newaoiShape
            }
        case 'SET_ANNOTATIONID':
            return {
                ...state,
                annotationid: action.annotationid
            }
        case 'SET_DISPLAY_PANEL':
            return {
                ...state,
                displayPanel: action.displayPanel
            }
        case 'SET_ALERTS_SIDEBAR':
            return {
                ...state,
                alertsSidebar: action.alertsSidebar
            }
        case 'SET_AOI_POLYGON':
            return {
                ...state,
                aoiPolygon: action.aoiPolygon
            }
        case 'SET_SHOW_LOGIN':
            return {
                ...state,
                showLogin: action.showLogin
            }
        case 'SET_SELECTED_ALERTS':
            return {
                ...state,
                selectedAlerts: action.selectedAlerts
            }
        case 'SET_SAVE_THIS_AOI':
            return {
                ...state,
                saveThisAoi: action.saveThisAoi
            }
        case 'LIMIT_TO_AOI':
            return {
                ...state,
                limitToAoi: action.limitToAoi
            }
        case 'SET_REGION':
            return {
                ...state,
                region: action.region
            }
        case 'SET_RELOAD_AOIS':
            return {
                ...state,
                reloadAois: action.reloadAois
            }
        case 'SET_INFO_TOPIC':
            return {
                ...state,
                infoTopic: action.infoTopic
            }
        case 'SET_EDIT_AOI':
            return {
                ...state,
                editAoi: action.editAoi
            }
        case 'SET_REPORT_DETAIL':
            return {
                ...state,
                reportDetail: action.reportDetail
            }
        case 'SET_REPORT_ID':
            return {
                ...state,
                reportId: action.reportId
            }
        case 'SET_SHOW_PW_CHANGE':
            return {
                ...state,
                showPwChange: action.showPwChange
            }
        case 'SET_CLOUD_COVERAGE':
            return {
                ...state,
                cloudCoverage: action.cloudCoverage
            }
        case 'SET_PLANET_DATE_RANGE':
            return {
                ...state,
                planetDateRange: action.planetDateRange
            }
        case 'SET_SHOW_REGISTER':
            return {
                ...state,
                showRegister: action.showRegister
            }
        case 'SET_SHOW_ADD_AOI':
            return {
                ...state,
                showAddAoi: action.showAddAoi
            }
        case 'SET_CLUSTER':
            return {
                ...state,
                cluster: action.cluster
            }
        case 'SET_SHOW_ALERTS_INFO_BOX':
            return {
                ...state,
                showAlertsInfoBox: action.showAlertsInfoBox
            }
        case 'SET_SHOW_LABELS':
            return {
                ...state,
                showLabels: action.showLabels
            }
        case 'SET_DRAW_SHAPE':
            return {
                ...state,
                drawShape: action.drawShape
            }
        case 'SET_USER_PROFILE':
            return {
                ...state,
                userProfile: action.userProfile
            }
        case 'SET_HEADER_BANNER':
            return {
                ...state,
                headerBanner: action.headerBanner
            }
        case 'SET_SHOW_BANNER':
            return {
                ...state,
                showBanner: action.showBanner
            }
        case 'SET_CONFIGURE_MAP':
            return {
                ...state,
                configureMap: action.configureMap
            }
        case 'SET_LAYERS':
            return {
                ...state,
                layers: action.layers
            }
        case 'SET_SAT_IMAGE_TYPE':
            return {
                ...state,
                satImageType: action.satImageType
            }
        case 'SET_SAT_IMAGE':
            return {
                ...state,
                satImage: action.satImage
            }
        case 'SET_LAYER':
            return {
                ...state,
                layer: action.layer
            }
        case 'SET_EXP':
            return {
                ...state,
                exp: action.exp
            }
        case 'SET_FEEDS':
            return {
                ...state,
                feeds: action.feeds
            }
        case 'SET_SELECTED_FEEDS':
            return {
                ...state,
                selectedFeeds: action.selectedFeeds
            }
        case 'SET_ISSUE_FEEDS':
            return {
                ...state,
                issueFeeds: action.issueFeeds
            }
        case 'SET_ANNOTATIONS':
            return {
                ...state,
                annotations: action.annotations
            }
        case 'SET_SHOW_OPEN':
            return {
                ...state,
                showOpen: action.showOpen
            }
        case 'SET_SHOW_SAVE':
            return {
                ...state,
                showSave: action.showSave
            }
        case 'SET_MAP_TYPE':
            return {
                ...state,
                mapType: action.mapType
            }
        case 'SET_PUBLIC_USER':
            return {
                ...state,
                publicUser: action.publicUser
            }
        case 'SET_MAPVIEW_NAME':
            return {
                ...state,
                mapviewName: action.mapviewName
            }
        case 'SET_ALERTS_KEYWORD':
            return {
                ...state,
                alertsKeyword: action.alertsKeyword
            }
        case 'SET_ALERTS_SEARCH_READY':
            return {
                ...state,
                alertsSearchReady: action.alertsSearchReady
            }
        case 'SET_INCLUDE_LEGEND':
            return {
                ...state,
                includeLegend: action.includeLegend
            }
        case 'SET_SHOW_TIMELINE':
            return {
                ...state,
                showTimeline: action.showTimeline
            }
        case 'SET_SHOW_LABS':
            return {
                ...state,
                showLabs: action.showLabs
            }
        case 'SET_COMPARE_IMAGES':
            return {
                ...state,
                compareImages: action.compareImages
            }
        case 'SET_ISSUE':
            return {
                ...state,
                issue: action.issue
            }
        case 'SET_LOCK_MAP':
            return {
                ...state,
                lockMap: action.lockMap
            }
        case 'SET_SETBACKS':
            return {
                ...state,
                setbacks: action.setbacks
            }
        case 'SET_LAYER_TIMELINE':
            return {
                ...state,
                layerTimeline: action.layerTimeline
            }
        case 'SET_LAYER_TIMELINE_MAP':
            return {
                ...state,
                layerTimelineMap: action.layerTimelineMap
            }
        case 'SET_MESSAGE':
            return {
                ...state,
                message: action.message
            }
        case 'SET_SHOW_SATELLITE':
            return {
                ...state,
                showSatellite: action.showSatellite
            }
        case 'SET_SHOW_PUBLIC_ISSUES':
            return {
                ...state,
                showPublicIssues: action.showPublicIssues
            }
        case 'SET_UGA':
            return {
                ...state,
                uga: action.uga
            }
        case 'SET_SHOW_NAV':
            return {
                ...state,
                showNav: action.showNav
            }
        case 'SET_RETURN_POSITION':
            return {
                ...state,
                returnPosition: action.returnPosition
            }
        case 'SET_REPORT_FROM':
            return {
                ...state,
                reportFrom: action.reportFrom
            }
        case 'SET_HELP_KEY': {
            if (state.helpKey === action.helpKey)
                return {
                    ...state,
                    helpKey: null
                }
            else
                return {
                    ...state,
                    helpKey: action.helpKey
                }
        }
        case 'SET_MAP_LAYERS_READY_TO_SHOW':
            return {
                ...state,
                mapLayersReadyToShow: action.mapLayersReadyToShow
            }
        case 'SET_LAYER_CHANGES':
            return {
                ...state,
                layerChanges: action.layerChanges
            }
        case 'SET_IMAGE_OPACITY':
            return {
                ...state,
                imageOpacity: action.imageOpacity
            }
        case 'SET_SHOW_ELEVATION':
            return {
                ...state,
                showElevation: action.showElevation
            }
        case 'SET_ORG_ALERTS':
            return {
                ...state,
                orgAlerts: action.orgAlerts
            }
        case 'SET_AGENDA_JSON':
            return {
                ...state,
                agendaJson: action.agendaJson
            }
        case 'SET_COMBINED_IMGS':
            return {
                ...state,
                combinedImgs: action.combinedImgs
            }
        case 'SET_SHOW_COMPARE':
            return {
                ...state,
                showCompare: action.showCompare
            }
        case 'SET_SAVE_ANNOTATIONS':
            return {
                ...state,
                saveAnnotations: action.saveAnnotations
            }
        case 'SET_MAP_POSITION':
            return {
                ...state,
                mapPosition: action.mapPosition
            }
        case 'SET_BBOX':
            return {
                ...state,
                bBox: action.bBox
            }
        case 'SET_LAYERSY':
            return {
                ...state,
                layersY: action.layersY
            }
        case 'SET_GAMMA':
            return {
                ...state,
                gamma: action.gamma
            }
        case 'SET_MAP_INSET':
            return {
                ...state,
                mapInset: action.mapInset
            }
        case 'SET_GEOMETRY':
            return {
                ...state,
                geometry: action.geometry
            }
        case 'SET_BRIGHTNESS':
            return {
                ...state,
                brightness: action.brightness
            }
        case 'SET_SHOW_BBOX':
            return {
                ...state,
                showBbox: action.showBbox
            }
        case 'SET_SHOW_SETTINGS':
            return {
                ...state,
                showSettings: action.showSettings
            }
        case 'SET_SCREENSHOT':
            return {
                ...state,
                screenshot: action.screenshot
            }
        case 'SET_COMPARE_UPDATE_SIDE':
            return {
                ...state,
                compareUpdateSide: action.compareUpdateSide
            }
        case 'SET_BASEMAP':
            return {
                ...state,
                basemap: action.basemap
            }
        case 'SET_THEME':
            return {
                ...state,
                theme: action.theme
            }
        case 'SET_SHOW_NOT_LOGGED_IN_OPTIONS':
            return {
                ...state,
                showNotLoggedInOptions: action.showNotLoggedInOptions
            }
        case 'SET_SHOW_INIT_TUTORIAL':
            return {
                ...state,
                showInitTutorial: action.showInitTutorial
            }
        case 'SET_SAVE_MAP':
            return {
                ...state,
                saveMap: action.saveMap
            }
        case 'SET_ISSUE_LAYERS':
            return {
                ...state,
                issueLayers: action.issueLayers
            }
        case 'SET_MAP_TITLE':
            return {
                ...state,
                mapTitle: action.mapTitle
            }
        default:
            return state;
    }
};
