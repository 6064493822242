export const current_data_tip = "Use the current map view as your AOI";

export const polygon_data_tip = "Draw a polygon to outline your AOI";

export const rectangle_data_tip = "Draw a rectangle to outline your AOI";

export const upload_data_tip = "Upload a GeoJSON file with your AOI";

export const nrc_tip ="";
//  "The National Response Center (NRC) serves as an emergency call center that fields " +
//     "INITIAL reports for pollution and " +
//     "railroad incidents and forwards that information to appropriate federal/state agencies for response. " +
//     "Website: http://www.nrc.uscg.mil";

// export const drilling_received_tip = ""; // "Oil and Gas drilling permits received. States: UT, WY.";
export const drilling_approved_tip = ""; //"Oil and Gas drilling permits approved. States: CO, MT, NM, PA, UT, WV, WY.";
export const drilling_violations_tip = ""; //"Violations of Oil and Gas drilling. States: PA.";
export const drilling_spud_tip = ""; //"Oil and Gas info, such as SPUD reports. States: MT, NM, PA, UT.";
export const pollution_tip = ""; //"Pollution reports. States: FL pollution; NM Oil & Gas Spills; NY Spills.";
export const custom_10102 = ""; //"This is a custom alert that is maintained by SkyTruth. Is is available to all users and is currently being tested as a part of developing organizational access in Alerts.";
// export const custom_10103 = "";
// export const custom_tip = ""; //"Custom alerts, emails and notes.";

export const keyword_search = "Type any keyword and click the Search button. Examples of what you can search for: bigspill, pipeline, " +
    "railroad, taylor energy, formaldehyde, incinerator, oil spill, gas leak. NOTE: Moving around the map with a keyword search " +
    "is significantly slower."

// export const planet_data_tip = "With 130+ satellites in orbit, Planet is able image anywhere on Earth daily at 3 meter " +
//     "resolution. Monitor your areas of interest, discover patterns, and deliver timely insights using Planet imagery.";

// export const sentinel_data_tip = "Sentinel-2 is an Earth observation mission from the EU Copernicus Programme that systematically " +
//     "acquires optical imagery at high spatial resolution (10m to 60m) over land and coastal waters.";

export const text_tip = "Add text to map";
export const polygon_tip = "Add a polygon";
export const rectangle_tip = "Add a rectangle";
export const circle_tip = "Add a circle";
export const polyline_tip = "Add a line";
export const marker_tip = "Add a marker or label";
// export const setback_tip = "Show setback distance";
export const download_tip = "Download GeoJSON";
export const selection_tip = "Modify a shape or text that you've already created";
