export const getTask = async (taskid) => {
    let headers = {
        "Content-Type": "application/json",
    };
    // return fetch(`http://localhost:8000/api/gettask/?taskid=${taskid}`, {headers, })
    return fetch(`https://skytruth-alerts2.appspot.com/api/gettask/?taskid=${taskid}`, {headers, })
    .then(async res => {
        if (res.status < 500) {
        return res.json().then(data => {
            return {status: res.status, data};
        })
        } else {
            console.log("Server Error!");
            throw res;
        }
    })
    .then(res => {
        if (res.status === 200) {
            return res.data;
        } else if (res.status >= 400 && res.status < 500) {
            throw res.data;
        }
    })
}
