import React from 'react';
import ReactDOM from 'react-dom';
import {Provider} from 'react-redux';
import { createStore, applyMiddleware, combineReducers } from "redux";
import thunk from "redux-thunk";
import AppRouter from './routers/AppRouter';
import mapReducers from './reducers/mapReducers';
import auth from './reducers/auth';
import 'normalize.css/normalize.css';
import './styles/styles.scss';

let store = createStore(combineReducers({
    mapreducers: mapReducers,
    auth: auth,
  }), applyMiddleware(thunk));


const jsx = (    
    <Provider store={store}>
        <AppRouter />
    </Provider>
);


ReactDOM.render(jsx, document.getElementById('app'));


